import axios, { AxiosInstance } from "axios";
import router from "@/router";
import { AuthTokens } from "@/types/auth";
import { useAuthStore } from "@/store/auth";
import Decimal from "decimal.js";
import { DateObject } from "@/classes";

// Create a custom interface extending AxiosInstance
interface CustomAxiosInstance extends AxiosInstance {
  $get<T>(url: string): Promise<T>;
  $post<T>(url: string, data: any): Promise<T>;
  $put<T>(url: string, data: any): Promise<T>;
  $delete<T>(url: string): Promise<T>;
}

Decimal.set({ precision: 100 });

const transformResponse = (responseText: string) => {
  return JSON.parse(responseText, (key, value) => {
    if (value !== null && typeof value === "object") {
      if (value._type === "decimal") {
        return new Decimal(value.value);
      } else if (value._type === "date") {
        return new Date(value.value);
      } else if (value._type === "datetime") {
        return new Date(value.value);
      }
    }
    return value;
  });
};

// Setup axios instance
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_URL,
  transformResponse: transformResponse,
}) as CustomAxiosInstance;

console.log(process.env.VUE_APP_API_URL);

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("auth_local")}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const auth = useAuthStore();
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      originalRequest.url !== "/auth/login" &&
      originalRequest.url !== "/auth/refresh" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        if (!auth.state.refresh) {
          await auth.refreshToken();
        } else {
          await auth.state.refresh;
        }

        axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
          "auth_local"
        )}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        auth.reset();
        router.push("/login");
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

// Add custom methods to axios instance
axiosInstance.$get = async function <T>(url: string): Promise<T> {
  const response = await axiosInstance.get(url);
  return response.data;
};

axiosInstance.$post = async function <T>(url: string, data: any): Promise<T> {
  const response = await axiosInstance.post(url, data);
  return response.data;
};

axiosInstance.$put = async function <T>(url: string, data: any): Promise<T> {
  const response = await axiosInstance.put(url, data);
  return response.data;
};

axiosInstance.$delete = async function <T>(url: string): Promise<T> {
  const response = await axiosInstance.delete(url);
  return response.data;
};

export default axiosInstance;
