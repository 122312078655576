<template>
  <div class="pa-3">
    <div class="d-flex">
      <h3>Asset Groups</h3>
      <v-spacer />
      <v-btn color="primary" flat @click="createAssetGroup">
        <v-icon class="mr-1">mdi-plus</v-icon>Asset Group
      </v-btn>
    </div>
    <div
      v-for="assetGroup in assetGroups"
      :key="assetGroup.id"
      class="d-flex align-center mt-2"
    >
      <v-row no-gutters>
        <v-col cols="6">
          <v-text-field
            v-model="assetGroup.name"
            label="Name"
            variant="outlined"
            density="compact"
            hide-details
            @update:focused="!$event && assetGroup.save()"
          />
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            class="ml-2"
            v-model="assetGroup.depot_id"
            label="Depot"
            :items="depots"
            item-value="id"
            item-title="name"
            variant="outlined"
            density="compact"
            hide-details
            @update:focused="!$event && assetGroup.save()"
          />
        </v-col>
      </v-row>
      <v-btn
        class="ml-2"
        icon="mdi-delete"
        flat
        density="compact"
        @click="deleteAssetGroup(assetGroup)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { AssetGroup, Depot } from "@/classes";
import { useConfirmStore } from "@/store/confirm";
import { useListStore } from "@/store/lists";
import { onMounted, onUnmounted, ref } from "vue";

const $confirm = useConfirmStore();
const lists = useListStore();
const depots = lists.useList<Depot>("depots");

const assetGroups = ref<AssetGroup[]>([]);
onMounted(async () => {
  assetGroups.value = await AssetGroup.list<AssetGroup>();
  assetGroups.value.sort((a, b) => a.id - b.id);
});
onUnmounted(() => {
  lists.refreshList("assetGroups");
});

const createAssetGroup = async () => {
  const newAssetGroup = new AssetGroup();
  await newAssetGroup.save();
  assetGroups.value.push(newAssetGroup);
};

const deleteAssetGroup = async (assetGroup: AssetGroup) => {
  $confirm.open({
    onConfirm: async () => {
      assetGroups.value = assetGroups.value.filter(
        (a) => a.id !== assetGroup.id
      );
      await assetGroup.deleteObject();
    },
  });
};
</script>
