<template>
  <div class="pa-3">
    <div class="d-flex">
      <h3>Business Units</h3>
      <v-spacer />
      <v-btn color="primary" flat @click="createLocation">
        <v-icon class="mr-1">mdi-plus</v-icon>Business Unit
      </v-btn>
    </div>
    <div
      v-for="location in locations"
      :key="location.id"
      class="d-flex align-center mt-2"
    >
      <v-row no-gutters>
        <v-col cols="6">
          <v-text-field
            v-model="location.name"
            label="Name"
            variant="outlined"
            density="compact"
            hide-details
            @update:focused="!$event && location.save()"
          />
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            class="ml-2"
            v-model="location.client_id"
            label="Company"
            :items="clients"
            item-value="id"
            item-title="name"
            variant="outlined"
            density="compact"
            hide-details
            @update:focused="!$event && location.save()"
          />
        </v-col>
      </v-row>
      <v-btn
        class="ml-2"
        icon="mdi-delete"
        flat
        density="compact"
        @click="deleteLocation(location)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Client, Location } from "@/classes";
import { useConfirmStore } from "@/store/confirm";
import { useListStore } from "@/store/lists";
import { onMounted, onUnmounted, ref } from "vue";

const $confirm = useConfirmStore();

const lists = useListStore();
const clients = lists.useList<Client>("clients");

const locations = ref<Location[]>([]);
onMounted(async () => {
  locations.value = await Location.list<Location>();
  locations.value.sort((a, b) => a.id - b.id);
});
onUnmounted(() => {
  lists.refreshList("locations");
});

const createLocation = async () => {
  const newLocation = new Location();
  await newLocation.save();
  locations.value.push(newLocation);
};

const deleteLocation = async (location: Location) => {
  $confirm.open({
    onConfirm: async () => {
      locations.value = locations.value.filter((l) => l.id !== location.id);
      await location.deleteObject();
    },
  });
};
</script>
