import { ref } from "vue";

export const setupApiQueue = () => {
  const loading = ref<Promise<any> | null>(null);

  const queueApiAction = async (action?: () => Promise<any>) => {
    try {
      if (loading.value) {
        await loading.value;
      }
    } catch (e) {
      console.error(e);
    }
    if (action) {
      try {
        loading.value = action();
        await loading.value;
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = null;
      }
    }
  };
  return { queueApiAction, loading };
};
