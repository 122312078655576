<template>
  <BaseDialog v-model="dialog" title="Process">
    <v-text-field
      v-model="process.name"
      label="Name"
      variant="outlined"
      density="compact"
      hide-details
    />
    <template #actions>
      <v-btn :loading="loading" :disabled="!valid" @click="save">Save</v-btn>
    </template>
  </BaseDialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import BaseDialog from "../base/dialogs/BaseDialog.vue";
import { Process } from "@/classes";

const emits = defineEmits(["change"]);

const dialog = ref(false);
const process = ref<Process>(new Process());
const loading = ref(false);

const valid = computed(() => {
  return !!process.value.name;
});

const open = async (
  id: number | null,
  templateId: number,
  number: number | null
) => {
  dialog.value = true;
  if (id) {
    process.value = await Process.get<Process>(id);
  } else {
    process.value = new Process();
  }
  process.value.assessment_template_id = templateId;
  if (number !== null) {
    process.value.number = number;
  }
};

const save = async () => {
  loading.value = true;
  await process.value.save();
  loading.value = false;
  dialog.value = false;
  emits("change");
};

defineExpose({ open });
</script>
