import Vue from "vue";
import { defineStore } from "pinia";
import { computed, reactive, ComputedRef } from "vue";
import {
  DatabaseObject,
  Client,
  Location,
  Role,
  WorkArea,
  AssessmentTemplate,
  Depot,
  AssetGroup,
  User,
  Process,
  ProcessStep,
} from "@/classes";
import { useAuthStore } from "./auth";

export interface ListState {
  users: User[];
  assessmentTemplates: AssessmentTemplate[];
  processes: Process[];
  processSteps: ProcessStep[];
  roles: Role[];
  locations: Location[];
  workAreas: WorkArea[];
  depots: Depot[];
  assetGroups: AssetGroup[];
  clients: Client[];
}

export type ListKey = keyof ListState;
export type ListType = ListState[ListKey];

const ConfigClasses: Record<ListKey, typeof DatabaseObject> = {
  users: User,
  assessmentTemplates: AssessmentTemplate,
  processes: Process,
  processSteps: ProcessStep,
  roles: Role,
  locations: Location,
  workAreas: WorkArea,
  depots: Depot,
  assetGroups: AssetGroup,
  clients: Client,
};

export interface ListStoreState {
  lists: ListState;
  fetched: Record<ListKey, boolean>;
}

export const useListStore = defineStore("list", () => {
  const state: ListStoreState = reactive({
    lists: {
      users: [],
      assessmentTemplates: [],
      processes: [],
      processSteps: [],
      roles: [],
      locations: [],
      workAreas: [],
      depots: [],
      assetGroups: [],
      clients: [],
    },
    fetched: {
      users: false,
      assessmentTemplates: false,
      processes: false,
      processSteps: false,
      roles: false,
      locations: false,
      workAreas: false,
      depots: false,
      assetGroups: false,
      clients: false,
    },
  });

  const useList = <T>(key: ListKey): ComputedRef<T[]> => {
    const auth = useAuthStore();

    const list: ComputedRef<T[]> = computed(() => {
      return state.lists[key] as unknown as T[];
    });
    if (!state.fetched[key] && auth.state.user?.id) {
      state.fetched[key] = true;
      ConfigClasses[key].list().then((data) => {
        // @ts-ignore
        state.lists[key] = data;
      });
    }
    return list;
  };

  const useFilteredList = <T>(key: ListKey, filter: (item: T) => boolean) => {
    useList<T>(key);
    return computed(() => {
      return (state.lists[key] as unknown as T[]).filter(filter);
    });
  };

  const refreshList = (key: ListKey) => {
    state.fetched[key] = false;
    useList(key);
  };

  const refreshLists = () => {
    Object.keys(state.fetched).forEach((key) => {
      refreshList(key as ListKey);
    });
  };

  return {
    useList,
    refreshList,
    refreshLists,
    useFilteredList,
  };
});
