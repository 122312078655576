import { Decimal } from "decimal.js";
import {
  AggregateQuery,
  DatabaseObject,
  Filters,
  PaginatedResponse,
} from "./class";
import { IframeHTMLAttributes } from "@vue/runtime-dom";
import axios from "axios";

export interface FileResponse {
  file: DBFile;
  upload_url: string;
}
export class DBFile extends DatabaseObject {
  static $route = "file";
  static $fileAxios = axios.create();

  filename: string | null = null;

  uploader_id: number | null = null;
  upload_timestamp: Date | null = null;
  upload_origin: string | null = null;

  extension: string | null = null;
  file_size: Decimal = new Decimal(0);

  static async uploadFile(fileObject: File) {
    const uploadData = {
      filename: fileObject.name,
      file_size: new Decimal(fileObject.size),
      content_type: fileObject.type,
    };
    const data = await this.$axios.$post<FileResponse>(
      `${this.$route}/upload-initial`,
      uploadData
    );
    const file = this.fromAxios(data.file);

    await this.$fileAxios.put(data.upload_url, fileObject, {
      headers: {
        "Content-Type": fileObject.type,
      },
    });

    await this.$axios.$get(`${this.$route}/upload-confirm/${file.id}`);
    return file;
  }

  async download() {
    const url = await DBFile.$axios.$get(
      `${DBFile.$route}/download/${this.id}`
    );
    const iframe = document.getElementById(
      "my_iframe"
    ) as unknown as IframeHTMLAttributes;
    if (!iframe) {
      throw new Error("Cannot find iframe");
    }
    // iframe.src = url;
  }

  async save(): Promise<void> {
    throw new Error("Cannot save file");
  }

  static async getReport(route: string, data: any): Promise<DBFile> {
    return this.fromAxios(await DBFile.$axios.$post<DBFile>(`${route}`, data));
  }
}

export type FileLinkType = "unknown" | "Example";

export class FileLink extends DatabaseObject {
  static $route = "file-link";

  name: string | null = null;
  file_id: number | null = null;
  link_type: FileLinkType = "unknown";
  link_id: number | null = null;

  file: DBFile | null = null;

  get filename() {
    return this.file?.filename;
  }

  get uploader_id() {
    return this.file?.uploader_id;
  }

  get upload_timestamp() {
    return this.file?.upload_timestamp;
  }

  get upload_origin() {
    return this.file?.upload_origin;
  }

  get extension() {
    return this.file?.extension;
  }

  get file_size() {
    return this.file?.file_size;
  }

  static async getLinks(linkType: FileLinkType, linkId: number) {
    return this.fromAxiosList<FileLink>(
      await this.$axios.$get(`${this.$route}/${linkType}?id=${linkId}`)
    );
  }

  static async create<T extends DatabaseObject>(
    object: T & FileLink,
    overrideRoute?: string
  ) {
    return await super.create(object, `${this.$route}/${object.link_type}`);
  }

  static async update<T extends DatabaseObject>(
    object: T & FileLink,
    overrideRoute?: string
  ) {
    return await super.update(object, `${this.$route}/${object.link_type}`);
  }

  static async deleteObject<T extends DatabaseObject>(
    object: T & FileLink,
    overrideRoute?: string
  ) {
    return await super.deleteObject(
      object,
      `${this.$route}/${object.link_type}`
    );
  }

  static async list<T extends DatabaseObject>(
    overrideRoute?: string
  ): Promise<T[]> {
    throw new Error("Cannot list file links");
  }

  static async filter<T extends DatabaseObject>(
    filter: Filters,
    overrideRoute?: string | undefined
  ): Promise<T[]> {
    throw new Error("Cannot filter file links");
  }

  static async paginated<T extends DatabaseObject>(
    filters: Filters,
    sorts: [string, boolean][],
    page: number,
    perPage: number,
    aggregates?: AggregateQuery,
    groupBy?: string[] | undefined
  ): Promise<PaginatedResponse<T>> {
    throw new Error("Cannot paginate file links");
  }
}
