import { createRouter, createWebHistory } from "vue-router";
import AuthLayout from "@/layouts/AuthLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { useAuthStore } from "@/store/auth";

// Dynamically import all components in the pages directory
const requireComponent = require.context("../pages", true, /\.vue$/);

const layouts = {
  "/login": AuthLayout,
  "/reset": AuthLayout,
};

const routes = requireComponent.keys().map((fileName) => {
  const componentConfig = requireComponent(fileName);
  const filePath = fileName.replace("./", "").replace(".vue", "");

  let routePath;

  if (filePath.endsWith("/index")) {
    // If the component is an index.vue, map it to the base URL of its folder
    routePath = "/" + filePath.replace("/index", "").toLowerCase();
  } else {
    // Otherwise, use the full file path as the route path
    routePath = "/" + filePath.toLowerCase();
  }

  return {
    path: routePath,
    component: componentConfig.default || componentConfig,
    meta: {
      layout: layouts[routePath] || DefaultLayout,
    },
  };
});

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const auth = useAuthStore();
  if (to.matched.length === 0) {
    next("/assessment-templates");
  }

  if (to.path === "/login") {
    auth.reset();
  } else if (to.path.startsWith("/reset")) {
    next();
  } else {
    if (!auth.state.user) {
      try {
        await auth.fetchUser();
      } catch (error) {
        next("/login");
      }
    }
  }

  next();
});

export default router;
