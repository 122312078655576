<template>
  <div class="pa-3">
    <div class="d-flex align-center">
      <v-autocomplete
        style="width: 350px; max-width: 350px"
        v-model="selectedInterview"
        label="Select Interview"
        :items="completion.interviews"
        item-value="id"
        item-title="name"
        variant="outlined"
        density="compact"
        hide-details
        clearable
        return-object
        @update:model-value="changeInterview"
      />
      <v-btn
        class="ml-3"
        icon="mdi-pencil"
        flat
        density="compact"
        @click="interviewsDialog = true"
      />
      <v-spacer />
      <span><b>Completion %:</b> {{ metrics.completion_rate.toFixed(2) }}</span>
      <span v-if="showScores" class="ml-8">
        <b>Average Score:</b>
        {{ formatAverageScore(shownAverage) }}
      </span>
      <v-btn
        v-tooltip:bottom="`${showScores ? 'Hide' : 'Show'} scores`"
        class="ml-3"
        :color="showScores ? 'primary' : 'grey-darken-1'"
        :icon="`mdi-numeric${showScores ? '' : '-off'}`"
        variant="text"
        density="compact"
        @click="showScores = !showScores"
      />
      <v-btn
        class="ml-3"
        icon="mdi-delete"
        flat
        density="compact"
        @click="deleteAssessment"
      />
    </div>
    <v-divider class="my-3" />
    <div class="d-flex">
      <v-treeview
        v-if="template.tree_items(appliedRoleIds).length > 0"
        class="border template-nav"
        v-model:activated="currentTreeIds"
        :items="template.tree_items(appliedRoleIds)"
        item-value="tree_id"
        item-title="title"
        density="compact"
        activatable
        @update:activated="refreshTreeItems"
      />
      <div v-if="activeQuestions.length > 0" class="flex-grow-1 ml-6">
        <div class="d-flex font-weight-bold">
          <div class="number-col pr-3">#</div>
          <v-row no-gutters>
            <v-col class="pr-3">Information</v-col>
          </v-row>
          <div class="actions-col">{{ showScores ? "Avg Score" : "" }}</div>
        </div>
        <template v-for="(question, i) in activeQuestions" :key="question.id">
          <v-divider v-if="i > 0" class="my-4" style="border-width: 2px" />
          <div class="d-flex" :class="{ 'mt-2': i === 0 }">
            <div class="number-col pr-3">{{ question.title }}</div>
            <v-row no-gutters>
              <v-col class="pr-3">
                <div class="d-flex flex-column">
                  <v-textarea
                    :model-value="question.context"
                    label="Context"
                    variant="outlined"
                    density="compact"
                    auto-grow
                    rows="1"
                    readonly
                    hide-details
                  />
                  <v-textarea
                    class="mt-2"
                    :model-value="question.focus_questions"
                    label="Focus Questions"
                    variant="outlined"
                    density="compact"
                    auto-grow
                    rows="1"
                    readonly
                    hide-details
                  />
                </div>
              </v-col>
            </v-row>
            <div class="d-flex actions-col">
              <v-badge
                color="primary"
                :model-value="getCount(question.id)"
                :content="getCount(question.id)"
              >
                <v-btn
                  icon="mdi-pencil"
                  flat
                  density="compact"
                  @click="
                    selectedQuestion = question;
                    openAnswers();
                  "
                />
              </v-badge>
              <span v-if="showScores" class="ml-3">
                {{ formatAverageScore(getAverageScore(question.id)) }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </div>

    <BaseDialog
      v-model="interviewsDialog"
      title="Manage Interviews"
      max-width="1000"
    >
      <template
        v-for="(interview, i) in completion.interviews"
        :key="interview.id"
      >
        <v-divider v-if="i > 0" class="my-3" />
        <v-row no-gutters>
          <v-col cols="4">
            <v-text-field
              v-model="interview.name"
              label="Interview Name"
              variant="outlined"
              density="compact"
              hide-details
              @update:focused="!$event && interview.save()"
            />
          </v-col>
          <v-col cols="8">
            <v-autocomplete
              class="ml-3"
              :model-value="interview.role_ids"
              label="Select Roles"
              :items="roles"
              item-value="id"
              item-title="name"
              variant="outlined"
              density="compact"
              hide-details
              multiple
              chips
              closable-chips
              @update:model-value="interview.manageRoles($event)"
            />
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <v-btn @click="addInterview">Add</v-btn>
      </template>
    </BaseDialog>

    <BaseDialog
      v-model="answersDialog"
      title="Assign Score and Comments"
      max-width="90%"
      hide-actions
    >
      <div
        v-for="(answer, i) in answers"
        :key="answer.id"
        class="d-flex align-center"
        :class="{ 'mt-2': i > 0 }"
      >
        <v-row no-gutters>
          <v-col cols="3">
            <v-autocomplete
              v-model="answer.role_id"
              label="Role"
              :items="roles"
              item-value="id"
              item-title="name"
              variant="outlined"
              density="compact"
              hide-details
              @update:focused="!$event && answer.save()"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              class="ml-2"
              v-model="answer.comment"
              label="Comment"
              variant="outlined"
              density="compact"
              hide-details
              @update:focused="!$event && answer.save()"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              class="ml-2"
              v-model="answer.rating_id"
              label="Score"
              :items="selectedQuestion.question_ratings"
              item-value="rating_id"
              item-title="display_name"
              variant="outlined"
              density="compact"
              hide-details
              @update:focused="!$event && answer.save()"
            />
          </v-col>
        </v-row>
        <v-btn
          class="ml-2"
          icon="mdi-delete"
          flat
          density="compact"
          @click="deleteAnswer(answer)"
        />
      </div>
      <v-divider
        v-if="
          answers.length > 0 && selectedQuestion.question_ratings.length > 0
        "
        class="my-3"
      />
      <div class="d-flex" style="gap: 12px">
        <v-textarea
          v-for="rating in selectedQuestion.question_ratings"
          :key="rating.id"
          :model-value="rating.description"
          :label="`${rating.rating?.number} - ${rating.rating?.name}`"
          variant="outlined"
          auto-grow
          hide-details
          readonly
        />
      </div>
      <template #toolbar-actions>
        <v-tooltip
          location="bottom"
          text="Please select an interview"
          :disabled="!!selectedInterview"
        >
          <template #activator="{ props }">
            <div v-bind="props">
              <v-btn :disabled="!selectedInterview" @click="addAnswer">
                <v-icon class="mr-1">mdi-plus</v-icon>Entry
              </v-btn>
            </div>
          </template>
        </v-tooltip>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup lang="ts">
import {
  Answer,
  AssessmentCompletion,
  AssessmentTemplate,
  Interview,
  Question,
  Role,
} from "@/classes";
import BaseDialog from "@/components/base/dialogs/BaseDialog.vue";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import axiosInstance from "@/plugins/axios";
import { AssessmentMetrics } from "@/types/metrics";
import { useListStore } from "@/store/lists";
import { useFilterStore } from "@/store/filter";
import { useConfirmStore } from "@/store/confirm";

const $confirm = useConfirmStore();

const filterStore = useFilterStore();
filterStore.hide();

const lists = useListStore();
const roles = lists.useList<Role>("roles");

const completion = ref<AssessmentCompletion>(new AssessmentCompletion());
const template = ref<AssessmentTemplate>(new AssessmentTemplate());

const selectedQuestion = ref<Question>(new Question());
const answersDialog = ref(false);

const answerRoles = computed(() => {
  return roles.value.filter((role) => {
    const applicable = selectedQuestion.value.question_roles.find(
      (qr) => qr.role_id === role.id
    );
    return !!applicable;
  });
});

watch(
  () => answersDialog.value,
  async () => {
    if (!answersDialog.value) {
      await getMetrics();
    }
  }
);

const route = useRoute();
onMounted(async () => {
  const id = parseInt(route.params.id as string);
  completion.value = await AssessmentCompletion.get<AssessmentCompletion>(id);
  template.value = await AssessmentTemplate.get<AssessmentTemplate>(
    completion.value.assessment_template_id
  );
  await getMetrics();
});

const router = useRouter();
const deleteAssessment = async () => {
  $confirm.open({
    onConfirm: async () => {
      await completion.value.deleteObject();
      router.push("/assessments");
    },
  });
};

const currentTreeIds = ref<string[]>([]);
const activeQuestions = ref<Question[]>([]);

const refreshTreeItems = async () => {
  activeQuestions.value = [];
  if (currentTreeIds.value.length > 0) {
    activeQuestions.value = findQuestions(currentTreeIds.value[0]);
    await getMetrics();
  }
};

const findQuestions = (treeId: string) => {
  for (const process of template.value.processes) {
    if (process.tree_id === treeId) {
      return process.questions.filter((question) =>
        question.hasRoleIds(appliedRoleIds.value)
      );
    } else {
      for (const processStep of process.process_steps) {
        if (processStep.tree_id === treeId) {
          return processStep.questions.filter((question) =>
            question.hasRoleIds(appliedRoleIds.value)
          );
        } else {
          for (const question of processStep.questions) {
            if (
              question.hasRoleIds(appliedRoleIds.value) &&
              question.tree_id === treeId
            ) {
              return [question];
            }
          }
        }
      }
    }
  }
  return [];
};

const metrics = ref<AssessmentMetrics>({
  completion_rate: 0,
  question_metrics: [],
});

const showScores = ref(true);
const shownAverage = computed(() => {
  const activeMetrics = metrics.value.question_metrics;
  if (activeMetrics.length > 0) {
    return activeMetrics.reduce((a, b) => a + b.avg, 0) / activeMetrics.length;
  }
  return 0;
});

const getMetrics = async () => {
  metrics.value = await axiosInstance.$post<AssessmentMetrics>(
    "/metrics/assessment",
    {
      assessment_completion_id: completion.value.id,
      interview_id: selectedInterview.value?.id || null,
    }
  );
};

const getAverageScore = (questionId: number) => {
  const metric = metrics.value.question_metrics.find(
    (metric) => metric.question_id === questionId
  );
  if (metric) {
    return metric.avg;
  }
  return null;
};

const getCount = (questionId: number) => {
  const metric = metrics.value.question_metrics.find(
    (metric) => metric.question_id === questionId
  );
  if (metric) {
    return metric.count;
  }
  return null;
};

const formatAverageScore = (score: number | null) => {
  if (score !== null) {
    return score.toFixed(2);
  }
  return "";
};

const answers = ref<Answer[]>([]);
const openAnswers = async () => {
  answers.value = [];
  answersDialog.value = true;
  answers.value = await Answer.filters<Answer>({
    interview_id: selectedInterview.value?.id || undefined,
    assessment_completion_id: completion.value.id,
    question_id: selectedQuestion.value.id,
  });
  answers.value.sort((a, b) => a.id - b.id);
};

const addAnswer = async () => {
  const newAnswer = new Answer();
  newAnswer.interview_id = selectedInterview.value?.id || null;
  newAnswer.assessment_completion_id = completion.value.id;
  newAnswer.question_id = selectedQuestion.value.id;
  await newAnswer.save();
  answers.value.push(newAnswer);
};

const deleteAnswer = async (answer: Answer) => {
  $confirm.open({
    onConfirm: async () => {
      answers.value = answers.value.filter((a) => a.id !== answer.id);
      await answer.deleteObject();
    },
  });
};

const selectedInterview = ref<Interview | null>(null);
const interviewsDialog = ref(false);
const appliedRoleIds = computed(() => {
  if (selectedInterview.value) {
    return selectedInterview.value.role_ids;
  }
  return [];
});

const changeInterview = async () => {
  if (currentTreeIds.value.length > 0) {
    activeQuestions.value = findQuestions(currentTreeIds.value[0]);
  }
  await getMetrics();
};

const addInterview = async () => {
  const newInterview = new Interview();
  newInterview.assessment_completion_id = completion.value.id;
  await newInterview.save();
  completion.value.interviews.push(newInterview);
};
</script>

<style scoped>
.template-nav {
  width: 350px;
}

.number-col {
  width: 80px;
}

.actions-col {
  width: 100px;
}
</style>
