import { DatabaseObject } from './class'

export class Company extends DatabaseObject {
  static $route = 'company'

  name: string = ''
  logo_file_id: number | null = null
  primary_theme_color: string = '#00BCD4'
  secondary_theme_color: string = '#272727'

  static async getCurrent(): Promise<Company> {
    return this.fromAxios(await this.$axios.$get<Company>(`/${this.$route}`))
  }
}
