<template>
  <div class="pa-3">
    <div class="d-flex">
      <h3>Regions</h3>
      <v-spacer />
      <v-btn color="primary" flat @click="createWorkArea">
        <v-icon class="mr-1">mdi-plus</v-icon>Region
      </v-btn>
    </div>
    <div
      v-for="workArea in workAreas"
      :key="workArea.id"
      class="d-flex align-center mt-2"
    >
      <v-row no-gutters>
        <v-col cols="6">
          <v-text-field
            v-model="workArea.name"
            label="Name"
            variant="outlined"
            density="compact"
            hide-details
            @update:focused="!$event && workArea.save()"
          />
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            class="ml-2"
            v-model="workArea.location_id"
            label="Business Unit"
            :items="locations"
            item-value="id"
            item-title="name"
            variant="outlined"
            density="compact"
            hide-details
            @update:focused="!$event && workArea.save()"
          />
        </v-col>
      </v-row>
      <v-btn
        class="ml-2"
        icon="mdi-delete"
        flat
        density="compact"
        @click="deleteWorkArea(workArea)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Location, WorkArea } from "@/classes";
import { useConfirmStore } from "@/store/confirm";
import { useListStore } from "@/store/lists";
import { onMounted, onUnmounted, ref } from "vue";

const $confirm = useConfirmStore();
const lists = useListStore();
const locations = lists.useList<Location>("locations");

const workAreas = ref<WorkArea[]>([]);
onMounted(async () => {
  workAreas.value = await WorkArea.list<WorkArea>();
  workAreas.value.sort((a, b) => a.id - b.id);
});
onUnmounted(() => {
  lists.refreshList("workAreas");
});

const createWorkArea = async () => {
  const newWorkArea = new WorkArea();
  await newWorkArea.save();
  workAreas.value.push(newWorkArea);
};

const deleteWorkArea = async (workArea: WorkArea) => {
  $confirm.open({
    onConfirm: async () => {
      workAreas.value = workAreas.value.filter((w) => w.id !== workArea.id);
      await workArea.deleteObject();
    },
  });
};
</script>
