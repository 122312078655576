<template>
  <v-text-field
    v-if="definition.type === 'string'"
    v-model="currentValue"
    :label="definition.label"
    variant="outlined"
    density="compact"
    hide-details
    clearable
    @keydown.enter="emitChange"
    @update:focused="handleChange"
  />
  <v-autocomplete
    v-else-if="definition.type === 'reflist'"
    v-model="currentValue"
    :label="definition.label"
    :items="items"
    :item-value="definition.itemValue"
    :item-title="definition.itemText"
    :multiple="definition.multiple"
    variant="outlined"
    density="compact"
    hide-details
    clearable
    @update:focused="handleChange"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { FilterDefinition } from "@/types/filter";
import { useListStore } from "@/store/lists";

const props = defineProps<{
  modelValue: any;
  definition: FilterDefinition;
}>();
const emit = defineEmits(["change"]);

const currentValue = ref<any>();

const lists = useListStore();
const items = computed(() => {
  if (props.definition.type === "reflist") {
    return lists.useList(props.definition.reflist).value;
  }
  return [];
});

watch(
  () => props.modelValue,
  () => {
    currentValue.value = props.modelValue;
  }
);

const handleChange = (value: boolean) => {
  if (!value && currentValue.value !== props.modelValue) {
    emitChange();
  }
};

const emitChange = () => {
  if (Array.isArray(currentValue.value)) {
    emit("change", currentValue.value.length > 0 ? currentValue.value : null);
  } else {
    emit("change", currentValue.value || null);
  }
};
</script>
