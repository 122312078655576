<template>
  <div class="pa-3">
    <div class="d-flex align-center">
      <v-text-field
        v-model="template.name"
        label="Template Name"
        density="compact"
        variant="outlined"
        hide-details
        @update:focused="!$event && template.save()"
      />
      <v-text-field
        class="ml-3"
        v-model="template.description"
        label="Template Description"
        density="compact"
        variant="outlined"
        hide-details
        @update:focused="!$event && template.save()"
      />
      <v-btn
        class="ml-3"
        icon="mdi-text-box-edit"
        flat
        density="compact"
        @click="templateRatingDialog?.open(template.id)"
      />
      <v-btn
        class="ml-3"
        icon="mdi-delete"
        flat
        density="compact"
        @click="deleteTemplate"
      />
    </div>
    <div class="d-flex mt-6">
      <div class="border template-nav flex-grow-0">
        <v-btn
          flat
          block
          @click="
            processDialog?.open(
              null,
              template.id,
              template.processes.length + 1
            )
          "
        >
          <v-icon class="mr-1">mdi-plus</v-icon>Process
        </v-btn>
        <v-treeview
          v-if="template.tree_items().length > 0"
          v-model:activated="currentTreeIds"
          :items="template.tree_items()"
          item-value="tree_id"
          item-title="title"
          density="compact"
          activatable
          @update:activated="refreshTreeItems"
        >
          <template #append="{ item }">
            <v-btn
              v-if="item.type !== 'question'"
              icon="mdi-pencil"
              variant="plain"
              size="small"
              density="compact"
              @click.stop="openEditDialog(item.id, item.parent_id, item.type)"
            />
            <v-btn
              v-if="item.type === 'process-step'"
              icon="mdi-plus"
              variant="plain"
              size="small"
              density="compact"
              :disabled="addingQuestion"
              @click.stop="addQuestion(item.id, item.children.length)"
            />
            <v-btn
              v-else-if="item.type !== 'question'"
              icon="mdi-plus"
              variant="plain"
              size="small"
              density="compact"
              @click.stop="
                openAddDialog(
                  null,
                  item.id,
                  item.type,
                  item.children.length + 1
                )
              "
            />
            <v-btn
              v-if="item.type === 'question' && item.deletable"
              icon="mdi-delete"
              variant="plain"
              size="small"
              density="compact"
              @click.stop="deleteQuestion(item.tree_id)"
            />
          </template>
        </v-treeview>
      </div>
      <div class="d-flex flex-column flex-grow-1 ml-6">
        <div v-for="(question, i) in activeQuestions" :key="question.id">
          <v-divider v-if="i > 0" class="my-6" />
          <div class="d-flex">
            <v-textarea
              v-model="question.focus_questions"
              label="Focus Questions"
              density="compact"
              variant="outlined"
              hide-details
              auto-grow
              @update:focused="!$event && question.save()"
            />
            <v-textarea
              class="ml-3"
              v-model="question.context"
              label="Context"
              density="compact"
              variant="outlined"
              hide-details
              auto-grow
              @update:focused="!$event && question.save()"
            />
          </div>
          <div class="mt-3 text-center">Maturity Level Explanation</div>
          <div class="d-flex mt-2" style="gap: 12px">
            <v-textarea
              v-for="rating in question.question_ratings"
              :key="rating.id"
              v-model="rating.description"
              :label="`${rating.rating?.number} - ${rating.rating?.name}`"
              variant="outlined"
              hide-details
              auto-grow
              @update:focused="!$event && rating.save()"
            />
          </div>
          <div class="d-flex align-center mt-3">
            <b>Applicable To:</b>
            <v-autocomplete
              class="ml-2"
              :model-value="question.role_ids"
              :items="roles"
              item-value="id"
              item-title="name"
              variant="outlined"
              density="compact"
              hide-details
              multiple
              chips
              closable-chips
              @update:model-value="question.addRole($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <TemplateRatingDialog ref="templateRatingDialog" @close="syncRatings" />
    <ProcessDialog ref="processDialog" @change="syncRatings" />
    <ProcessStepDialog ref="processStepDialog" @change="syncRatings" />
  </div>
</template>

<script setup lang="ts">
import { AssessmentTemplate, Question, Role } from "@/classes";
import TemplateRatingDialog from "@/components/dialogs/TemplateRatingDialog.vue";
import ProcessDialog from "@/components/dialogs/ProcessDialog.vue";
import ProcessStepDialog from "@/components/dialogs/ProcessStepDialog.vue";
import { useFilterStore } from "@/store/filter";
import { onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useListStore } from "@/store/lists";
import { useConfirmStore } from "@/store/confirm";

const $confirm = useConfirmStore();

const filterStore = useFilterStore();
filterStore.hide();

const lists = useListStore();
const roles = lists.useList<Role>("roles");

const template = ref<AssessmentTemplate>(new AssessmentTemplate());

const route = useRoute();
onMounted(async () => {
  const id = parseInt(route.params.id as string);
  template.value = await AssessmentTemplate.get<AssessmentTemplate>(id);
});
onUnmounted(() => {
  lists.refreshList("assessmentTemplates");
  lists.refreshList("processes");
  lists.refreshList("processSteps");
});

const router = useRouter();
const deleteTemplate = async () => {
  $confirm.open({
    onConfirm: async () => {
      await template.value.deleteObject();
      router.push("/assessment-templates");
    },
  });
};

const syncRatings = async () => {
  await template.value.syncRatings();
  refreshTreeItems();
};

const templateRatingDialog = ref<InstanceType<
  typeof TemplateRatingDialog
> | null>(null);
const processDialog = ref<InstanceType<typeof ProcessDialog> | null>(null);
const processStepDialog = ref<InstanceType<typeof ProcessStepDialog> | null>(
  null
);

const addingQuestion = ref(false);
const addQuestion = async (processStepId: number, childrenCount: number) => {
  addingQuestion.value = true;
  const newQuestion = new Question();
  newQuestion.number = childrenCount + 1;
  newQuestion.process_step_id = processStepId;
  await newQuestion.save();
  await syncRatings();
  addingQuestion.value = false;
};

const openAddDialog = async (
  id: number | null,
  parentId: number,
  type: string,
  number: number
) => {
  if (type === "process") {
    await processStepDialog.value?.open(id, parentId, number);
  }
};

const openEditDialog = async (
  id: number | null,
  parentId: number,
  type: string
) => {
  if (type === "process") {
    await processDialog.value?.open(id, parentId, null);
  } else if (type === "process-step") {
    await processStepDialog.value?.open(id, parentId, null);
  }
};

const currentTreeIds = ref<string[]>([]);
const activeQuestions = ref<Question[]>([]);
const refreshTreeItems = () => {
  activeQuestions.value = [];
  if (currentTreeIds.value.length > 0) {
    activeQuestions.value = findQuestions(currentTreeIds.value[0]);
  }
};

const findQuestions = (treeId: string) => {
  for (const process of template.value.processes) {
    if (process.tree_id === treeId) {
      return process.questions;
    } else {
      for (const processStep of process.process_steps) {
        if (processStep.tree_id === treeId) {
          return processStep.questions;
        } else {
          for (const question of processStep.questions) {
            if (question.tree_id === treeId) {
              return [question];
            }
          }
        }
      }
    }
  }
  return [];
};

const deleteQuestion = async (treeId: string) => {
  $confirm.open({
    onConfirm: async () => {
      const questions = findQuestions(treeId);
      if (questions.length > 0) {
        const deletedQuestion = questions[0];
        await deletedQuestion.deleteObject();
        currentTreeIds.value = currentTreeIds.value.filter(
          (id) => id !== treeId
        );
        await syncRatings();
      }
    },
  });
};
</script>

<style scoped>
.template-nav {
  width: 350px;
}
</style>
