<template>
  <PaginatedTable
    ref="table"
    table="assessments"
    :table-class="AssessmentCompletion"
    :filter-definitions="filterDefinitions"
    :headers="headers"
  >
    <template #right-actions>
      <v-btn color="primary" flat @click="dialog?.open(null)">
        <v-icon class="mr-1">mdi-plus</v-icon>Assessment
      </v-btn>
    </template>

    <template #item.completion_rate="{ item }">
      {{ item.completion_rate.toFixed(2) }}%
    </template>

    <template #item.average_score="{ item }">
      {{ item.average_score.toFixed(2) }}
    </template>

    <template #item.actions="{ item }">
      <v-btn
        icon="mdi-pencil"
        flat
        density="compact"
        @click="dialog?.open(item.id, false)"
      />
      <v-btn
        icon="mdi-open-in-new"
        flat
        density="compact"
        :to="`/assessment/${item.id}`"
      />
    </template>

    <AssessmentDialog ref="dialog" @change="table?.getData" />
  </PaginatedTable>
</template>

<script setup lang="ts">
import { AssessmentCompletion } from "@/classes";
import PaginatedTable from "@/components/base/tables/PaginatedTable.vue";
import AssessmentDialog from "@/components/dialogs/AssessmentDialog.vue";
import { FilterDefinition } from "@/types/filter";
import { DataTableHeader } from "@/types/table";
import { ref } from "vue";

const headers: DataTableHeader[] = [
  {
    title: "Assessment Name",
    key: "name",
  },
  {
    title: "Template Name",
    key: "assessment_template_name",
  },
  {
    title: "Company",
    key: "client_name",
  },
  {
    title: "Business Unit",
    key: "location_name",
  },
  {
    title: "Region",
    key: "work_area_name",
  },
  {
    title: "Site",
    key: "depot_name",
  },
  {
    title: "Asset Group",
    key: "asset_group_name",
  },
  {
    title: "Completion Rate",
    key: "completion_rate",
  },
  {
    title: "Average Score",
    key: "average_score",
  },
  {
    title: "Completed By",
    key: "completed_by_name",
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
];

const filterDefinitions: FilterDefinition[] = [
  {
    label: "Assessment Name",
    value: "name",
    type: "string",
  },
  {
    type: "reflist",
    reflist: "assessmentTemplates",
    value: "assessment_template_id",
    label: "Assessment Template",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "clients",
    value: "client_id",
    label: "Company",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "locations",
    value: "location_id",
    label: "Business Unit",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "workAreas",
    value: "work_area_id",
    label: "Region",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "depots",
    value: "depot_id",
    label: "Site",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "assetGroups",
    value: "asset_group_id",
    label: "Asset Group",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "users",
    value: "completed_by_id",
    label: "Completed By",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
];

const table = ref<InstanceType<typeof PaginatedTable> | null>(null);
const dialog = ref<InstanceType<typeof AssessmentDialog> | null>(null);
</script>
