<template>
  <v-app>
    <v-app-bar dark app flat color="grey-lighten-2">
      <v-app-bar-title>
        <div class="d-flex align-center">
          <img src="@/assets/logo-svg.svg" alt="Vantage" style="height: 50px" />
        </div>
      </v-app-bar-title>
      <template #append>
        <v-menu offset-y>
          <template #activator="{ props }">
            <v-btn icon dark v-bind="props">
              <v-icon icon="mdi-account"> </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-icon class="mr-2" icon="mdi-account"></v-icon>
              {{ auth.state.user?.email }}
            </v-list-item>
            <v-list-item @click="logout">
              <v-icon class="mr-2" icon="mdi-logout"></v-icon>
              Logout
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>

    <v-navigation-drawer
      permanent
      :rail="filterStore.state.name && !filterStore.state.drawer"
      :width="310"
    >
      <div class="nav-container">
        <div
          class="d-flex flex-column"
          style="min-width: 55px; width: 55px !important"
        >
          <v-btn
            class="mx-auto my-1"
            icon="mdi-filter"
            flat
            density="comfortable"
            :disabled="!filterStore.state.name"
            @click="filterStore.state.drawer = !filterStore.state.drawer"
          />
          <v-divider />
          <v-list density="compact" nav>
            <v-tooltip v-for="(page, i) in pages" :key="i" :text="page.name">
              <template #activator="{ props }">
                <v-list-item v-bind="props" :to="page.path">
                  <v-icon>{{ page.icon }}</v-icon>
                </v-list-item>
              </template>
            </v-tooltip>
          </v-list>
        </div>
        <div v-if="filterStore.state.name" class="filters-panel width-full">
          <FilterList class="py-2 px-3" />
        </div>
      </div>
    </v-navigation-drawer>

    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
import router from "@/router";
import { useAuthStore } from "@/store/auth";
import { useFilterStore } from "@/store/filter";
import FilterList from "@/components/base/filters/FilterList.vue";

const auth = useAuthStore();
const filterStore = useFilterStore();

const pages = [
  {
    name: "Assessment Templates",
    path: "/assessment-templates",
    icon: "mdi-folder-multiple",
  },
  {
    name: "Assessments",
    path: "/assessments",
    icon: "mdi-folder-edit",
  },
  {
    name: "Metrics",
    path: "/metrics",
    icon: "mdi-chart-bar",
  },
  {
    name: "Map",
    path: "/map",
    icon: "mdi-map",
  },
  {
    name: "Users",
    path: "/users",
    icon: "mdi-account-group",
  },
  {
    name: "Settings",
    path: "/settings",
    icon: "mdi-cog",
  },
];

const logout = () => {
  auth.reset();
  router.push("/login");
};
</script>

<style scoped>
.nav-container {
  display: flex;
  height: 100%;
}

.filters-panel {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
