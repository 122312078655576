<template>
  <div>
    <v-toolbar color="grey-lighten-2" density="compact">
      <v-tabs v-model="currentTab">
        <v-tab v-for="tab in tabs" :key="tab" :text="tab" :value="tab" />
      </v-tabs>
    </v-toolbar>

    <ClientSettings v-if="currentTab === 'Companies'" />
    <LocationSettings v-else-if="currentTab === 'Business Units'" />
    <WorkAreaSettings v-else-if="currentTab === 'Regions'" />
    <DepotSettings v-else-if="currentTab === 'Sites'" />
    <AssetGroupSettings v-else-if="currentTab === 'Asset Groups'" />
    <RoleSettings v-else-if="currentTab === 'Roles'" />
  </div>
</template>

<script setup lang="ts">
import AssetGroupSettings from "@/components/settings/AssetGroupSettings.vue";
import ClientSettings from "@/components/settings/ClientSettings.vue";
import DepotSettings from "@/components/settings/DepotSettings.vue";
import LocationSettings from "@/components/settings/LocationSettings.vue";
import RoleSettings from "@/components/settings/RoleSettings.vue";
import WorkAreaSettings from "@/components/settings/WorkAreaSettings.vue";
import { useFilterStore } from "@/store/filter";
import { ref } from "vue";

const filterStore = useFilterStore();
filterStore.hide();

const tabs = [
  "Companies",
  "Business Units",
  "Regions",
  "Sites",
  "Asset Groups",
  "Roles",
] as const;
type Tab = (typeof tabs)[number];

const currentTab = ref<Tab>("Companies");
</script>
