import { defineStore } from "pinia";
import { reactive } from "vue";

export const useNotifierStore = defineStore("notifier", () => {
  const state = reactive({
    opened: false,
    message: "",
    color: "green",
    timeout: 2000,
  });
  const close = () => {
    state.opened = false;
  };
  const open = (message: string, color: string, timeout = 2000) => {
    state.message = message;
    state.color = color;
    state.timeout = timeout;
    state.opened = true;
  };

  const success = (message: string, timeout = 2000) => {
    open(message, "green", timeout);
  };

  const error = (message: string, timeout = 2000) => {
    open(message, "red", timeout);
  };

  return { close, state, open, success, error };
});
