// import { Decimal } from "decimal.js";

// const formatterCurrency = new Intl.NumberFormat("en-AU", {
//   style: "currency",
//   currency: "AUD",
//   minimumFractionDigits: 2, // Minimum Decimal Place
//   maximumFractionDigits: 2, // Maximum Decimal Place
// });
// const formatterCurrencyNoDP = new Intl.NumberFormat("en-AU", {
//   style: "currency",
//   currency: "AUD",
//   minimumFractionDigits: 0, // Minimum Decimal Place
//   maximumFractionDigits: 0, // Maximum Decimal Place
// });
// const formatterDecimal = new Intl.NumberFormat("en-AU", {
//   style: "decimal",
//   currency: "AUD",
//   minimumFractionDigits: 2, // Minimum Decimal Place
//   maximumFractionDigits: 2, // Maximum Decimal Place
// });

// const formatFunctions = () => ({
//   toLocaleString(val: string | number | Date) {
//     return val !== null && val !== undefined ? val.toLocaleString("en") : "";
//   },
//   parseLocaleFloat(val: string, digits: number = 2) {
//     return val === "" || val === null
//       ? null
//       : parseFloat(
//           parseFloat(val.replaceAll(",", "").replaceAll("$", "")).toFixed(
//             digits
//           )
//         );
//   },
//   parsePositiveLocaleFloat(val: string, digits: number = 2) {
//     return val === ""
//       ? null
//       : parseFloat(
//           Math.max(
//             0,
//             parseFloat(val.replaceAll(",", "").replaceAll("$", ""))
//           ).toFixed(digits)
//         );
//   },
//   parseLocaleInt(val: string) {
//     return val === ""
//       ? null
//       : Math.min(
//           2147483647,
//           parseInt(String(val).replaceAll(",", "").replaceAll("$", ""))
//         );
//   },
//   parsePositiveLocaleInt(val: string) {
//     return val === ""
//       ? null
//       : Math.max(
//           0,
//           Math.min(
//             2147483647,
//             parseInt(val.replaceAll(",", "").replaceAll("$", ""))
//           )
//         );
//   },
//   currency(val: null | Decimal, ifnull: string = "$0.00") {
//     if (val === null) {
//       return ifnull;
//     } else {
//       return formatterCurrency.format(val.toNumber());
//     }
//   },
//   commarize(number: number) {
//     if (!number) {
//       return number;
//     }
//     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   },
//   thousandSeperator(x: number) {
//     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   },
//   currencyNoDP(val: number) {
//     if (val === null || val === undefined) {
//       return null;
//     } else if (Math.abs(val) < 0.0001) {
//       return "$0";
//     } else {
//       return formatterCurrencyNoDP.format(val);
//     }
//   },
//   decimal(val: null | Decimal, ifnull: string | null = "0.00") {
//     if (val === null) {
//       return ifnull;
//     } else {
//       return formatterDecimal.format(val.toNumber());
//     }
//   },
//   capitaliseFirst(val: string) {
//     return (val.charAt(0).toUpperCase() + val.slice(1)).replaceAll("_", " ");
//   },
//   capitaliseEach(val: string) {
//     return val
//       .replaceAll("_", " ")
//       .replaceAll("-", " ")
//       .split(" ")
//       .map(function (a) {
//         return a.charAt(0).toUpperCase() + a.slice(1);
//       })
//       .join(" ");
//   },
//   limit(text: string | null, characters: number) {
//     if (!text || text.length <= characters) {
//       return text;
//     }
//     return text.substring(0, characters) + "...";
//   },
// });

// export type Format = ReturnType<typeof formatFunctions>;

// export default formatFunctions;

const capitaliseEach = (val: string) => {
  return val
    .replaceAll("_", " ")
    .replaceAll("-", " ")
    .split(" ")
    .map(function (a) {
      return a.charAt(0).toUpperCase() + a.slice(1);
    })
    .join(" ");
};

export const $format = {
  capitaliseEach,
};
