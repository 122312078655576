<template>
  <div class="pa-3">
    <div class="d-flex">
      <h3>Companies</h3>
      <v-spacer />
      <v-btn color="primary" flat @click="createClient">
        <v-icon class="mr-1">mdi-plus</v-icon>Company
      </v-btn>
    </div>
    <div
      v-for="client in clients"
      :key="client.id"
      class="d-flex align-center mt-2"
    >
      <v-text-field
        v-model="client.name"
        label="Name"
        variant="outlined"
        density="compact"
        hide-details
        @update:focused="!$event && client.save()"
      />
      <v-btn
        class="ml-2"
        icon="mdi-delete"
        flat
        density="compact"
        @click="deleteClient(client)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Client } from "@/classes";
import { useConfirmStore } from "@/store/confirm";
import { useListStore } from "@/store/lists";
import { onMounted, onUnmounted, ref } from "vue";

const $confirm = useConfirmStore();

const lists = useListStore();
onUnmounted(() => {
  lists.refreshList("clients");
});

const clients = ref<Client[]>([]);
onMounted(async () => {
  clients.value = await Client.list<Client>();
  clients.value.sort((a, b) => a.id - b.id);
});

const createClient = async () => {
  const newClient = new Client();
  await newClient.save();
  clients.value.push(newClient);
};

const deleteClient = async (client: Client) => {
  $confirm.open({
    onConfirm: async () => {
      clients.value = clients.value.filter((c) => c.id !== client.id);
      await client.deleteObject();
    },
  });
};
</script>
