import { QuestionMetrics } from "@/types/metrics";
import { DatabaseObject } from "./class";
import type { Role } from "./config";
import { User } from "./user";

export class AssessmentTemplate extends DatabaseObject {
  static $route = "assessment-template";

  name: string = "";
  description: string = "";
  created_by_id: number | null = null;

  created_by: User | null = null;
  processes: Process[] = [];
  ratings: Rating[] = [];

  get created_by_name() {
    return this.created_by?.name || "";
  }

  get questions() {
    return this.processes.flatMap((process) => process.questions);
  }

  tree_items(roleIds: number[] = []) {
    return this.processes
      .filter((process) => process.hasRoleIds(roleIds))
      .map((process) => process.tree_items(roleIds));
  }

  async syncRatings() {
    const data = await AssessmentTemplate.get<AssessmentTemplate>(
      this.id,
      `${AssessmentTemplate.$route}/sync-ratings`
    );
    Object.assign(this, data);
  }
}

export class Rating extends DatabaseObject {
  static $route = "rating";

  name: string = "";
  description: string = "";
  number: number = 0;
  assessment_template_id: number = 0;

  assessment_template: AssessmentTemplate | null = null;
}

export class Process extends DatabaseObject {
  static $route = "process";

  number: number = 0;
  name: string = "";
  assessment_template_id: number = 0;

  assessment_template: AssessmentTemplate | null = null;
  process_steps: ProcessStep[] = [];

  get tree_id() {
    return `${Process.$route}-${this.id}`;
  }

  get questions() {
    return this.process_steps.flatMap((processStep) => processStep.questions);
  }

  hasRoleIds(roleIds: number[] = []) {
    if (roleIds.length === 0) return true;
    const validProcessSteps = this.process_steps.filter((processStep) =>
      processStep.hasRoleIds(roleIds)
    );
    return validProcessSteps.length > 0;
  }

  tree_items(roleIds: number[] = []) {
    return {
      id: this.id,
      parent_id: this.assessment_template_id,
      tree_id: this.tree_id,
      title: `${this.number}.0.0 ${this.name}`,
      type: Process.$route,
      children: this.process_steps
        .filter((processStep) => processStep.hasRoleIds(roleIds))
        .map((processStep) => processStep.tree_items(roleIds)),
      deletable: false,
    };
  }
}

export class ProcessStep extends DatabaseObject {
  static $route = "process-step";

  number: number = 0;
  name: string = "";
  process_id: number = 0;

  process: Process | null = null;
  questions: Question[] = [];

  get tree_id() {
    return `${ProcessStep.$route}-${this.id}`;
  }

  hasRoleIds(roleIds: number[] = []) {
    if (roleIds.length === 0) return true;
    const validQuestions = this.questions.filter((question) =>
      question.hasRoleIds(roleIds)
    );
    return validQuestions.length > 0;
  }

  tree_items(roleIds: number[] = []) {
    const filteredQuestions = this.questions.filter((question) =>
      question.hasRoleIds(roleIds)
    );
    return {
      id: this.id,
      parent_id: this.process_id,
      tree_id: this.tree_id,
      title: `${this.process.number}.${this.number}.0 ${this.name}`,
      type: ProcessStep.$route,
      children: filteredQuestions.map((question, i) =>
        question.tree_items(i === filteredQuestions.length - 1)
      ),
      deletable: false,
    };
  }
}

export class Question extends DatabaseObject {
  static $route = "question";

  number: number = 0;
  focus_questions: string = "";
  context: string = "";
  process_step_id: number = 0;

  process_step: ProcessStep | null = null;
  question_ratings: QuestionRating[] = [];
  question_roles: QuestionRole[] = [];

  get tree_id() {
    return `${Question.$route}-${this.id}`;
  }

  get title() {
    return `${this.process_step.process.number}.${this.process_step.number}.${this.number}`;
  }

  get role_ids() {
    return this.question_roles.map((role) => role.role_id);
  }

  tree_items(deletable: boolean = false) {
    return {
      id: this.id,
      parent_id: this.process_step_id,
      tree_id: this.tree_id,
      title: this.title,
      type: Question.$route,
      deletable: deletable,
    };
  }

  hasRoleIds(roleIds: number[] = []) {
    if (roleIds.length === 0) return true;
    return this.role_ids.some((roleId) => roleIds.includes(roleId));
  }

  async addRole(roleIds: number[]) {
    for (const roleId of roleIds) {
      const existingRole = this.question_roles.find(
        (role) => role.role_id === roleId
      );
      if (!existingRole) {
        const newQuestionRole = new QuestionRole();
        newQuestionRole.question_id = this.id;
        newQuestionRole.role_id = roleId;
        this.question_roles.push(newQuestionRole);
        await newQuestionRole.save();
      }
    }
    const deleteRoleIds = this.role_ids.filter(
      (roleId) => !roleIds.includes(roleId)
    );
    for (const roleId of deleteRoleIds) {
      const existingRole = this.question_roles.find(
        (role) => role.role_id === roleId
      );
      if (existingRole) {
        this.question_roles = this.question_roles.filter(
          (role) => role.id !== existingRole.id
        );
        await existingRole.deleteObject();
      }
    }
  }
}

export class QuestionRating extends DatabaseObject {
  static $route = "question-rating";

  rating_id: number = 0;
  question_id: number = 0;
  description: string = "";

  rating: Rating | null = null;
  question: Question | null = null;

  get rating_number() {
    return this.rating?.number || 0;
  }

  get rating_name() {
    return this.rating?.name || "";
  }

  get display_name() {
    return `${this.rating_number} - ${this.rating_name}`;
  }
}

export class QuestionRole extends DatabaseObject {
  static $route = "question-role";

  question_id: number = 0;
  role_id: number = 0;

  question: Question | null = null;
  role: Role | null = null;
}
