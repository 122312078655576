<template>
  <BaseDialog v-model="dialog" title="Assessment">
    <v-text-field
      v-model="completion.name"
      label="Assessment Name"
      variant="outlined"
      density="compact"
      :readonly="!!completion.id"
    />
    <v-autocomplete
      v-model="completion.assessment_template_id"
      label="Select Template"
      :items="assessmentTemplates"
      item-value="id"
      item-title="name"
      variant="outlined"
      density="compact"
      :readonly="!!completion.id"
    />
    <v-autocomplete
      v-model="completion.client_id"
      label="Select Company"
      :items="clients"
      item-value="id"
      item-title="name"
      variant="outlined"
      density="compact"
      @update:model-value="resolveLinks"
    />
    <v-autocomplete
      v-model="completion.location_id"
      label="Select Business Unit"
      :items="availableLocations"
      item-value="id"
      item-title="name"
      variant="outlined"
      density="compact"
      @update:model-value="resolveLinks"
    />
    <v-autocomplete
      v-model="completion.work_area_id"
      label="Select Region"
      :items="availableWorkAreas"
      item-value="id"
      item-title="name"
      variant="outlined"
      density="compact"
      @update:model-value="resolveLinks"
    />
    <v-autocomplete
      v-model="completion.depot_id"
      label="Select Site"
      :items="availableDepots"
      item-value="id"
      item-title="name"
      variant="outlined"
      density="compact"
      @update:model-value="resolveLinks"
    />
    <v-autocomplete
      v-model="completion.asset_group_id"
      label="Select Asset Group"
      :items="availableAssetGroups"
      item-value="id"
      item-title="name"
      variant="outlined"
      density="compact"
    />
    <v-date-picker v-model="completion.date" />
    <template #actions>
      <v-btn :loading="loading" :disabled="!valid" @click="save">Save</v-btn>
    </template>
  </BaseDialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import BaseDialog from "../base/dialogs/BaseDialog.vue";
import {
  AssessmentCompletion,
  AssessmentTemplate,
  Client,
  Depot,
  AssetGroup,
  Location,
  WorkArea,
} from "@/classes";
import { useListStore } from "@/store/lists";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth";
import { useNotifierStore } from "@/store/notifier";

const emits = defineEmits(["change"]);

const lists = useListStore();
const assessmentTemplates = lists.useList<AssessmentTemplate>(
  "assessmentTemplates"
);
const clients = lists.useList<Client>("clients");
const locations = lists.useList<Location>("locations");
const workAreas = lists.useList<WorkArea>("workAreas");
const depots = lists.useList<Depot>("depots");
const assetGroups = lists.useList<AssetGroup>("assetGroups");

const availableLocations = computed(() => {
  return locations.value.filter(
    (location) => location.client_id === completion.value.client_id
  );
});
const availableWorkAreas = computed(() => {
  return workAreas.value.filter(
    (workArea) => workArea.location_id === completion.value.location_id
  );
});
const availableDepots = computed(() => {
  return depots.value.filter(
    (depot) => depot.work_area_id === completion.value.work_area_id
  );
});
const availableAssetGroups = computed(() => {
  return assetGroups.value.filter(
    (assetGroup) => assetGroup.depot_id === completion.value.depot_id
  );
});

const dialog = ref(false);
const completion = ref<AssessmentCompletion>(new AssessmentCompletion());
const loading = ref(false);

const valid = computed(() => {
  return (
    !!completion.value.name &&
    !!completion.value.assessment_template_id &&
    !!completion.value.client_id &&
    !!completion.value.location_id &&
    !!completion.value.work_area_id &&
    !!completion.value.depot_id &&
    !!completion.value.asset_group_id
  );
});

const redirect = ref(true);
const open = async (id: number | null, redirectMode = true) => {
  dialog.value = true;
  redirect.value = redirectMode;
  if (id) {
    completion.value = await AssessmentCompletion.get<AssessmentCompletion>(id);
  } else {
    completion.value = new AssessmentCompletion();
  }
};

const auth = useAuthStore();
const notifier = useNotifierStore();
const router = useRouter();
const save = async () => {
  try {
    loading.value = true;
    completion.value.completed_by_id = auth.state.user.id;
    await completion.value.save();
    dialog.value = false;
    if (redirect.value) {
      router.push(`/assessment/${completion.value.id}`);
    } else {
      emits("change");
    }
  } catch (e) {
    notifier.error(e.response?.data?.detail || "An error has occurred.");
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const resolveLinks = () => {
  const existingLocation = availableLocations.value.find(
    (location) => location.id === completion.value.location_id
  );
  if (!existingLocation) {
    completion.value.location_id = null;
  }
  const existingWorkArea = availableWorkAreas.value.find(
    (workArea) => workArea.id === completion.value.work_area_id
  );
  if (!existingWorkArea) {
    completion.value.work_area_id = null;
  }
  const existingDepot = availableDepots.value.find(
    (depot) => depot.id === completion.value.depot_id
  );
  if (!existingDepot) {
    completion.value.depot_id = null;
  }
  const existingAssetGroup = availableAssetGroups.value.find(
    (assetGroup) => assetGroup.id === completion.value.asset_group_id
  );
  if (!existingAssetGroup) {
    completion.value.asset_group_id = null;
  }
};

defineExpose({ open });
</script>
