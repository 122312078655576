import { PaginatedResponse, APIObject, DatabaseObject } from "./class";
import type {
  AggregateQuery,
  Filters,
  Aggregate,
  PaginatedFunction,
  Relationship,
  AggregateResponse,
  AuthUser,
  AuthCompany,
} from "./class";
import type { DateGroup } from "./group";
import { GroupTableObject } from "./group";
import { Company } from "./company";
import { User } from "./user";
import { DateObject } from "./datetime";
import { DBFile, FileLink } from "./file";
import type { FileLinkType } from "./file";
import { TrackPoint } from "./track";
import { Role, Client, WorkArea, Location, Depot, AssetGroup } from "./config";
import {
  Interview,
  InterviewRole,
  AssessmentCompletion,
  Answer,
} from "./completed";
import {
  AssessmentTemplate,
  Process,
  ProcessStep,
  Question,
  QuestionRating,
  Rating,
  QuestionRole,
} from "./assessment";

// Relationship definitions
User.relationships = {
  company: {
    dataClass: Company,
    backref: null,
  },
};

FileLink.relationships = {
  file: { dataClass: DBFile, backref: null },
};

AssessmentTemplate.relationships = {
  created_by: { dataClass: User, backref: null },
  processes: { dataClass: Process, backref: "assessment_template" },
};

Rating.relationships = {
  assessment_template: { dataClass: AssessmentTemplate, backref: null },
};

Process.relationships = {
  assessment_template: { dataClass: AssessmentTemplate, backref: null },
  process_steps: { dataClass: ProcessStep, backref: "process" },
};

ProcessStep.relationships = {
  process: { dataClass: Process, backref: null },
  questions: { dataClass: Question, backref: "process_step" },
};

Question.relationships = {
  process_step: { dataClass: ProcessStep, backref: null },
  question_ratings: { dataClass: QuestionRating, backref: null },
  question_roles: { dataClass: QuestionRole, backref: null },
};

QuestionRating.relationships = {
  question: { dataClass: Question, backref: null },
  rating: { dataClass: Rating, backref: null },
};

QuestionRole.relationships = {
  question: { dataClass: Question, backref: null },
  role: { dataClass: Role, backref: null },
};

Client.relationships = {
  locations: { dataClass: Location, backref: "client" },
};

Location.relationships = {
  client: { dataClass: Client, backref: null },
  work_areas: { dataClass: WorkArea, backref: "location" },
};

WorkArea.relationships = {
  location: { dataClass: Location, backref: null },
  depots: { dataClass: Depot, backref: "work_area" },
  assessment_completions: {
    dataClass: AssessmentCompletion,
    backref: "work_area",
  },
};

Depot.relationships = {
  work_area: { dataClass: WorkArea, backref: null },
};

Interview.relationships = {
  interview_roles: { dataClass: InterviewRole, backref: null },
};

AssessmentCompletion.relationships = {
  assessment_template: { dataClass: AssessmentTemplate, backref: null },
  client: { dataClass: Client, backref: null },
  location: { dataClass: Location, backref: null },
  work_area: { dataClass: WorkArea, backref: null },
  depot: { dataClass: Depot, backref: null },
  asset_group: { dataClass: AssetGroup, backref: null },
  completed_by: { dataClass: User, backref: null },
  interviews: { dataClass: Interview, backref: null },
};

Answer.relationships = {
  assessment_completion: { dataClass: AssessmentCompletion, backref: null },
  question: { dataClass: Question, backref: null },
  rating: { dataClass: Rating, backref: null },
};

export {
  Filters,
  APIObject,
  AggregateQuery,
  Aggregate,
  AggregateResponse,
  PaginatedResponse,
  Relationship,
  DatabaseObject,
  PaginatedFunction,
  DateGroup,
  GroupTableObject,
  Company,
  User,
  DateObject,
  DBFile,
  FileLink,
  FileLinkType,
  AuthUser,
  AuthCompany,
  TrackPoint,
  Role,
  Client,
  WorkArea,
  Location,
  Depot,
  AssetGroup,
  Interview,
  InterviewRole,
  AssessmentCompletion,
  Answer,
  AssessmentTemplate,
  Process,
  ProcessStep,
  Question,
  QuestionRating,
  Rating,
  QuestionRole,
};
