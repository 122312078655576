<template>
  <div class="width-full">
    Filters
    <div class="d-flex align-center width-full">
      <small class="text-medium-emphasis">
        {{ $format.capitaliseEach(filters.state.name) }}
      </small>
      <v-spacer />
      <v-btn variant="text" size="x-small" @click="filters.clearAll">
        Clear
      </v-btn>
    </div>
    <v-divider class="my-2" />
    <div v-for="(definition, i) in filters.definitions" :key="i">
      <FilterField
        :model-value="filters.values[definition.value]"
        :definition="definition"
        @change="filters.values[definition.value] = $event"
      />
      <v-divider v-if="i < filters.definitions.length - 1" class="my-2" />
    </div>
    <v-divider class="mt-2" />
  </div>
</template>

<script setup lang="ts">
import { useFilterStore } from "@/store/filter";
import FilterField from "./FilterField.vue";
import { $format } from "@/plugins/format";

const filters = useFilterStore();
</script>
