<template>
  <div class="height-full">
    <div
      style="
        margin: auto;
        margin-top: 24px;
        width: 400px;
        height: 400px;
        max-width: 100% !important;
      "
    >
      <div class="reset-title mt-8 my-4">Reset Password</div>
      <v-text-field
        v-model="password"
        label="Password"
        :rules="$rules.password"
        type="password"
        outlined
        dense
        hide-details="auto"
      />
      <v-text-field
        class="mt-2"
        v-model="confirm_password"
        label="Confirm Password"
        :rules="$rules.match('Password', password)"
        outlined
        dense
        hide-details="auto"
        type="password"
      />
      <v-btn
        class="mt-4"
        color="blue darken-3 white--text"
        depressed
        block
        :disabled="vailidate"
        @click="resetPassword"
      >
        Reset
      </v-btn>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useAuthStore } from "@/store/auth";
import { useRoute } from "vue-router";
import router from "@/router";
import { useNotifierStore } from "@/store/notifier";

const notifier = useNotifierStore();

const token = useRoute().query.token;
console.log(token);

const auth = useAuthStore();

const password = ref("");
const confirm_password = ref("");

const vailidate = computed(() => {
  return (
    !(password.value == confirm_password.value) || password.value.length < 8
  );
});

const $rules = {
  password: [
    (v) => !!v || "Password is required",
    (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
  ],
  match: (field, target) => [
    (v) => !!v || `${field} is required`,
    (v) => v === target || `${field} must match`,
  ],
};

const resetPassword = async () => {
  try {
    await auth.setPasswordwithToken(token, password.value);
    router.push("/login");
  } catch (error) {
    notifier.error("Password reset failed");
    console.error(error);
  }
};
</script>
