import { DatabaseObject } from "./class";
import type { Company } from "./company";

export class User extends DatabaseObject {
  static $route = "user";

  email: string | null = null;
  password: string | null = null;
  first_name: string | null = null;
  last_name: string | null = null;
  admin = false;

  company: Company | null = null;

  get name() {
    return `${this.first_name} ${this.last_name}`;
  }
}
