import { defineStore } from "pinia";
import { reactive } from "vue";
import axiosInstance from "@/plugins/axios";
import { AuthUser, AuthTokens } from "@/types/auth";

export const useAuthStore = defineStore("auth", () => {
  const state = reactive({
    user: null as AuthUser | null,
    refresh: null as Promise<any> | null,
  });

  const setTokens = (accessToken: string, refreshToken: string) => {
    localStorage.setItem("auth_local", accessToken);
    localStorage.setItem("auth_refresh", refreshToken);
  };

  const login = async (email: string, password: string) => {
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    const res = await axiosInstance.$post<AuthTokens>("/auth/login", formData);
    setTokens(res.access_token, res.refresh_token);
  };

  const resetPassword = async (email: string) => {
    return await axiosInstance.$post(`/auth/reset?email=${email}`, null);
  };

  const setPasswordwithToken = async (token: string, password: string) => {
    return await axiosInstance.$post("/auth/reset/token", { token, password });
  };

  const fetchUser = async () => {
    state.user = await axiosInstance.$get("auth/user");
  };

  const reset = () => {
    state.user = null;
    localStorage.removeItem("auth_local");
    localStorage.removeItem("auth_refresh");
  };

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem("auth_refresh");
    state.refresh = axiosInstance.$post<AuthTokens>("/auth/refresh", {
      refresh_token: refreshToken,
    });

    const res = await state.refresh;
    setTokens(res.access_token, res.refresh_token);
    state.refresh = null;
  };

  return {
    state,
    setTokens,
    login,
    fetchUser,
    reset,
    resetPassword,
    setPasswordwithToken,
    refreshToken,
  };
});
