export interface QuestionMetrics {
  question_id: number;
  avg: number;
  count: number;
}

export interface AssessmentMetrics {
  completion_rate: number;
  question_metrics: QuestionMetrics[];
}

export const dataOptions = ["Average Score"];
export const groupByOptions = [
  "Process",
  "Process Step",
  "Company",
  "Business Unit",
  "Region",
  "Site",
  "Asset Group",
  "Role Being Assessed",
];
export const colourByOptions = [
  "Process",
  "Process Step",
  "Company",
  "Business Unit",
  "Region",
  "Site",
  "Asset Group",
  "Role Being Assessed",
];

export type DataOption = (typeof dataOptions)[number];
export type GroupByOption = (typeof groupByOptions)[number];
export type ColourByOption = (typeof colourByOptions)[number];

export interface AggregateMetrics {
  colour_by_id: number;
  colour_by_label: string;
  group_by_id: number;
  group_by_label: string;
  value: number;
}

export interface MapMetrics {
  asset_group_id: number;
  asset_group_name: string | null;
  depot_id: number;
  depot_name: string | null;
  latitude: string | null;
  longitude: string | null;
  work_area_id: number;
  work_area_name: string | null;
  location_id: number;
  location_name: string | null;
  client_id: number;
  client_name: string | null;
  average: number;
}

export class ClientMetric {
  id: number = 0;
  name: string | null = null;
  location_metrics: LocationMetric[] = [];

  constructor(
    id: number,
    name: string | null,
    location_metrics: LocationMetric[]
  ) {
    this.id = id;
    this.name = name;
    this.location_metrics = location_metrics;
  }

  get location_averages() {
    return this.location_metrics.flatMap((metric) => metric.work_area_averages);
  }

  get average() {
    return (
      this.location_averages.reduce((a, b) => a + b) /
      this.location_averages.length
    );
  }
}

export class LocationMetric {
  id: number = 0;
  name: string | null = null;
  work_area_metrics: WorkAreaMetric[] = [];

  client_id: number = 0;
  client_name: string | null = null;

  constructor(
    id: number,
    name: string | null,
    work_area_metrics: WorkAreaMetric[],
    client_id: number,
    client_name: string | null
  ) {
    this.id = id;
    this.name = name;
    this.work_area_metrics = work_area_metrics;
    this.client_id = client_id;
    this.client_name = client_name;
  }

  get work_area_averages() {
    return this.work_area_metrics.flatMap((metric) => metric.depot_averages);
  }

  get average() {
    return (
      this.work_area_averages.reduce((a, b) => a + b) /
      this.work_area_averages.length
    );
  }
}

export class WorkAreaMetric {
  id: number = 0;
  name: string | null = null;
  depot_metrics: DepotMetric[] = [];

  location_id: number = 0;
  location_name: string | null = null;
  client_id: number = 0;
  client_name: string | null = null;

  constructor(
    id: number,
    name: string | null,
    depot_metrics: DepotMetric[],
    location_id: number,
    location_name: string | null,
    client_id: number,
    client_name: string | null
  ) {
    this.id = id;
    this.name = name;
    this.depot_metrics = depot_metrics;
    this.location_id = location_id;
    this.location_name = location_name;
    this.client_id = client_id;
    this.client_name = client_name;
  }

  get depot_averages() {
    return this.depot_metrics.flatMap((metric) => metric.asset_group_averages);
  }

  get average() {
    return (
      this.depot_averages.reduce((a, b) => a + b) / this.depot_averages.length
    );
  }
}

export class DepotMetric {
  id: number = 0;
  name: string | null = null;
  latitude: string | null = null;
  longitude: string | null = null;
  asset_group_metrics: AssetGroupMetric[] = [];

  work_area_id: number = 0;
  work_area_name: string | null = null;
  location_id: number = 0;
  location_name: string | null = null;
  client_id: number = 0;
  client_name: string | null = null;

  constructor(
    id: number,
    name: string | null,
    latitude: string | null,
    longitude: string | null,
    asset_group_metrics: AssetGroupMetric[],
    work_area_id: number,
    work_area_name: string | null,
    location_id: number,
    location_name: string | null,
    client_id: number,
    client_name: string | null
  ) {
    this.id = id;
    this.name = name;
    this.latitude = latitude;
    this.longitude = longitude;
    this.asset_group_metrics = asset_group_metrics;
    this.work_area_id = work_area_id;
    this.work_area_name = work_area_name;
    this.location_id = location_id;
    this.location_name = location_name;
    this.client_id = client_id;
    this.client_name = client_name;
  }

  get asset_group_averages() {
    return this.asset_group_metrics.flatMap((metric) => metric.averages);
  }

  get average() {
    return (
      this.asset_group_averages.reduce((a, b) => a + b) /
      this.asset_group_averages.length
    );
  }
}

export class AssetGroupMetric {
  id: number = 0;
  name: string | null = null;
  latitude: string | null = null;
  longitude: string | null = null;
  averages: number[] = [];

  depot_id: number = 0;
  depot_name: string | null = null;
  work_area_id: number = 0;
  work_area_name: string | null = null;
  location_id: number = 0;
  location_name: string | null = null;
  client_id: number = 0;
  client_name: string | null = null;

  constructor(
    id: number,
    name: string | null,
    latitude: string | null,
    longitude: string | null,
    averages: number[],
    depot_id: number,
    depot_name: string | null,
    work_area_id: number,
    work_area_name: string | null,
    location_id: number,
    location_name: string | null,
    client_id: number,
    client_name: string | null
  ) {
    this.id = id;
    this.name = name;
    this.latitude = latitude;
    this.longitude = longitude;
    this.averages = averages;
    this.depot_id = depot_id;
    this.depot_name = depot_name;
    this.work_area_id = work_area_id;
    this.work_area_name = work_area_name;
    this.location_id = location_id;
    this.location_name = location_name;
    this.client_id = client_id;
    this.client_name = client_name;
  }

  get average() {
    return this.averages.reduce((a, b) => a + b) / this.averages.length;
  }
}
