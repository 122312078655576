<template>
  <div>
    <div class="d-flex pa-2">
      <v-spacer />
      <slot name="right-actions" />
    </div>
    <v-divider />
    <v-data-table-server
      :items="data.items"
      :headers="headers"
      :loading="!!loading"
      :items-length="data.count"
      @update:options="updateOptions"
    >
      <template v-for="header in headers" #[`item.${header.key}`]="{ item }">
        <slot :name="`item.${header.key}`" :item="item">
          {{ item[header.key] }}
        </slot>
      </template>
    </v-data-table-server>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { APIObject, DatabaseObject, PaginatedResponse } from "@/classes";
import { useFilterStore } from "@/store/filter";
import { FilterDefinition } from "@/types/filter";
import { DataTableHeader, DataTableOptions } from "@/types/table";
import { setupApiQueue } from "../api-queue";

const props = defineProps<{
  table: string;
  tableClass: typeof APIObject;
  filterDefinitions: FilterDefinition[];
  headers: DataTableHeader[];
}>();

const filterStore = useFilterStore();
filterStore.initialise(props.table, props.filterDefinitions);

watch(
  () => filterStore.values,
  async () => {
    await getData();
  },
  { deep: true }
);

const { loading, queueApiAction } = setupApiQueue();

const options = ref<DataTableOptions>({
  itemsPerPage: 10,
  page: 1,
  sortBy: [],
});
const data = ref<PaginatedResponse<DatabaseObject>>(
  new PaginatedResponse<DatabaseObject>()
);

const updateOptions = async (dataTableOptions: DataTableOptions) => {
  options.value = dataTableOptions;
  await getData();
};

const getData = async () => {
  const sorts = options.value.sortBy.map(
    (sort) => [sort.key, sort.order === "desc"] as [string, boolean]
  );
  await queueApiAction(async () => {
    data.value = await props.tableClass.paginated(
      filterStore.values,
      sorts,
      options.value.page - 1,
      options.value.itemsPerPage
    );
  });
};

defineExpose({ getData });
</script>
