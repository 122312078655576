<template>
  <div v-if="!reset" class="login-container mx-auto mt-8 text-center">
    <img src="@/assets/logo-svg.svg" :width="500" />
    <h3>Login to Vantage</h3>
    <v-text-field
      class="mt-2"
      v-model="email"
      label="Email"
      variant="outlined"
      density="compact"
      hide-details
      @keydown.enter="login"
    />
    <v-text-field
      class="mt-2"
      v-model="password"
      label="Password"
      type="password"
      variant="outlined"
      density="compact"
      hide-details
      @keydown.enter="login"
    />
    <v-btn class="mt-4" color="primary" flat block @click="login">
      Login
    </v-btn>

    <v-btn class="mt-4" color="primary" flat block @click="reset = true">
      Reset Password
    </v-btn>
  </div>
  <div v-else class="login-container mx-auto mt-8 text-center">
    <h3>Reset Password</h3>
    <v-text-field
      v-model="email"
      label="Email"
      variant="outlined"
      density="compact"
      hide-details
    />
    <v-btn class="mt-4" color="primary" flat block @click="resetPassword">
      Reset
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useAuthStore } from "@/store/auth";
import router from "@/router";
import { useNotifierStore } from "@/store/notifier";

const notifier = useNotifierStore();
const auth = useAuthStore();

const email = ref("");
const password = ref("");

const reset = ref(false);

const resetPassword = async () => {
  try {
    await auth.resetPassword(email.value);
    reset.value = false;
    notifier.success("Password reset email sent");
  } catch (error) {
    notifier.error("Invalid email");
    console.error(error);
  }
};

const login = async () => {
  try {
    await auth.login(email.value, password.value);
    router.push("/assessment-templates");
  } catch (error) {
    notifier.error("Invalid email or password");
    console.error(error);
  }
};
</script>

<style scoped>
.login-container {
  max-width: 500px;
}
</style>
