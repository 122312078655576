<template>
  <PaginatedTable
    table="assessment-templates"
    :table-class="AssessmentTemplate"
    :filter-definitions="filterDefinitions"
    :headers="headers"
  >
    <template #right-actions>
      <v-btn color="primary" flat @click="createTemplate">
        <v-icon class="mr-1">mdi-plus</v-icon>Template
      </v-btn>
    </template>

    <template #item.actions="{ item }">
      <v-btn
        icon="mdi-open-in-new"
        flat
        density="compact"
        :to="`/assessment-template/${item.id}`"
      />
    </template>
  </PaginatedTable>
</template>

<script setup lang="ts">
import { AssessmentTemplate } from "@/classes";
import PaginatedTable from "@/components/base/tables/PaginatedTable.vue";
import router from "@/router";
import { useAuthStore } from "@/store/auth";
import { useListStore } from "@/store/lists";
import { FilterDefinition } from "@/types/filter";
import { DataTableHeader } from "@/types/table";
import { onUnmounted } from "vue";

const auth = useAuthStore();

const lists = useListStore();
onUnmounted(() => {
  lists.refreshList("assessmentTemplates");
  lists.refreshList("processes");
  lists.refreshList("processSteps");
});

const headers: DataTableHeader[] = [
  {
    title: "Template Name",
    key: "name",
  },
  {
    title: "Created By",
    key: "created_by_name",
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
];

const filterDefinitions: FilterDefinition[] = [
  {
    label: "Template Name",
    value: "name",
    type: "string",
  },
  {
    type: "reflist",
    reflist: "users",
    value: "created_by_id",
    label: "Created By",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
];

const createTemplate = async () => {
  const newTemplate = new AssessmentTemplate();
  newTemplate.created_by_id = auth.state.user.id;
  await newTemplate.save();
  router.push(`/assessment-template/${newTemplate.id}`);
};
</script>
