import { DatabaseObject } from "./class";
import type { AssessmentCompletion } from "./completed";

export class Client extends DatabaseObject {
  static $route = "client";

  name: string = "";

  locations: Location[] = [];
}

export class Location extends DatabaseObject {
  static $route = "location";

  name: string = "";
  client_id: number | null = null;

  client: Client | null = null;
  work_areas: WorkArea[] = [];
}

export class WorkArea extends DatabaseObject {
  static $route = "work-area";

  name: string = "";
  location_id: number | null = null;

  location: Location | null = null;
  depots: Depot[] = [];
  assessment_completions: AssessmentCompletion[] = [];
}

export class Depot extends DatabaseObject {
  static $route = "depot";

  name: string = "";
  latitude: string = "";
  longitude: string = "";
  work_area_id: number | null = null;

  work_area: WorkArea | null = null;
}

export class AssetGroup extends DatabaseObject {
  static $route = "asset-group";

  name: string = "";
  depot_id: number | null = null;
}

export class Role extends DatabaseObject {
  static $route = "role";

  name: string = "";
}
