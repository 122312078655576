<template>
  <PaginatedTable
    ref="table"
    table="users"
    :table-class="User"
    :filter-definitions="filterDefinitions"
    :headers="headers"
  >
    <template #right-actions>
      <v-btn color="primary" flat @click="dialog?.open(null)">
        <v-icon class="mr-1">mdi-plus</v-icon>User
      </v-btn>
    </template>

    <template #item.admin="{ item }">
      <v-checkbox-btn v-model="item.admin" readonly />
    </template>

    <template #item.actions="{ item }">
      <v-btn
        icon="mdi-pencil"
        flat
        density="compact"
        @click="dialog?.open(item.id)"
      />
    </template>

    <UserDialog ref="dialog" @change="table?.getData()" />
  </PaginatedTable>
</template>

<script setup lang="ts">
import { User } from "@/classes";
import PaginatedTable from "@/components/base/tables/PaginatedTable.vue";
import UserDialog from "@/components/dialogs/UserDialog.vue";
import { useListStore } from "@/store/lists";
import { FilterDefinition } from "@/types/filter";
import { DataTableHeader } from "@/types/table";
import { onUnmounted, ref } from "vue";

const lists = useListStore();
onUnmounted(() => {
  lists.refreshList("users");
});

const headers: DataTableHeader[] = [
  {
    title: "Email",
    key: "email",
  },
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Admin",
    key: "admin",
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
];

const filterDefinitions: FilterDefinition[] = [
  {
    label: "Email",
    value: "email",
    type: "string",
  },
];

const table = ref<InstanceType<typeof PaginatedTable> | null>(null);
const dialog = ref<InstanceType<typeof UserDialog> | null>(null);
</script>
