<template>
  <div class="pa-3">
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="assessmentTemplateId"
          :items="assessmentTemplates"
          item-value="id"
          item-title="name"
          label="Assesment Template"
          variant="outlined"
          density="compact"
          @update:model-value="changeOptions"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="dataOption"
          :items="dataOptions"
          label="Data"
          variant="outlined"
          density="compact"
          @update:model-value="changeOptions"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="groupByOption"
          :items="groupByOptions"
          label="Group By"
          variant="outlined"
          density="compact"
          @update:model-value="changeOptions"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="colourByOption"
          :items="colourByOptions"
          label="Colour By"
          variant="outlined"
          density="compact"
          @update:model-value="changeOptions"
        />
      </v-col>
    </v-row>
    <div style="height: 500px; width: 100%">
      <Bar
        style="height: 100%; width: 100%"
        :data="chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartData,
  ChartOptions,
  ChartDataset,
} from "chart.js";
import { onMounted, ref, watch } from "vue";
import { Bar } from "vue-chartjs";
import {
  dataOptions,
  groupByOptions,
  colourByOptions,
  DataOption,
  GroupByOption,
  ColourByOption,
  AggregateMetrics,
} from "@/types/metrics";
import axiosInstance from "@/plugins/axios";
import { useFilterStore } from "@/store/filter";
import { FilterDefinition } from "@/types/filter";
import { useListStore } from "@/store/lists";
import { AssessmentTemplate } from "@/classes";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const dataOption = ref<DataOption>("Average Score");
const groupByOption = ref<GroupByOption>("Process");
const colourByOption = ref<ColourByOption>("Process");
const assessmentTemplateId = ref<number | null>(null);

const lists = useListStore();
const assessmentTemplates = lists.useList<AssessmentTemplate>(
  "assessmentTemplates"
);

const chartData = ref<ChartData<"bar">>({
  labels: [],
  datasets: [],
});

const chartOptions = ref<ChartOptions<"bar">>({
  responsive: true,
  maintainAspectRatio: false,
  skipNull: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (ctx) => {
          return `${ctx.dataset.label}: ${ctx.parsed.y.toFixed(2)}`;
        },
      },
    },
  },
});

onMounted(async () => {
  await getData();
});

const filterStore = useFilterStore();

const filterDefinitions: FilterDefinition[] = [
  {
    type: "reflist",
    reflist: "processes",
    value: "process_ids",
    label: "Process",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "processSteps",
    value: "process_step_ids",
    label: "Process Step",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "clients",
    value: "client_ids",
    label: "Company",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "locations",
    value: "location_ids",
    label: "Business Unit",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "workAreas",
    value: "work_area_ids",
    label: "Region",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "depots",
    value: "depot_ids",
    label: "Site",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "depots",
    value: "asset_group_ids",
    label: "Asset Group",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "roles",
    value: "role_ids",
    label: "Role Being Assessed",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "users",
    value: "user_ids",
    label: "Completed By",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
];

filterStore.initialise("metrics", filterDefinitions);

watch(
  () => filterStore.values,
  async () => {
    await getData();
  },
  { deep: true }
);

const getData = async () => {
  const res = await axiosInstance.$post<AggregateMetrics[]>(
    "/metrics/aggregate",
    {
      assessment_template_id: assessmentTemplateId.value,
      data_option: dataOption.value,
      group_by_option: groupByOption.value,
      colour_by_option: colourByOption.value,
      ...filterStore.values,
    }
  );

  const labelObjects: { group_by_id: number; group_by_label: string }[] = [];
  const setObjects: { colour_by_id: number; colour_by_label: string }[] = [];
  res.forEach((data) => {
    const label = labelObjects.find((o) => o.group_by_id === data.group_by_id);
    if (!label) {
      labelObjects.push({
        group_by_id: data.group_by_id,
        group_by_label: data.group_by_label,
      });
    }
    const set = setObjects.find((o) => o.colour_by_id === data.colour_by_id);
    if (!set) {
      setObjects.push({
        colour_by_id: data.colour_by_id,
        colour_by_label: data.colour_by_label,
      });
    }
  });

  const datasets: ChartDataset<"bar">[] = setObjects.map((set) => {
    const hue = Math.floor(Math.random() * 360);
    return {
      label: set.colour_by_label,
      backgroundColor: `hsla(${hue}, 60%, 60%, 0.3)`,
      data: labelObjects.map((label) => {
        const dataPoint = res.find(
          (data) =>
            data.group_by_id === label.group_by_id &&
            data.colour_by_id === set.colour_by_id
        );
        if (dataPoint) {
          return dataPoint.value;
        }
        return null;
      }),
    };
  });

  chartData.value = {
    labels: labelObjects.map((label) => label.group_by_label),
    datasets: datasets,
  };
};

const changeOptions = async () => {
  if (!dataOption.value) {
    dataOption.value = dataOptions[0];
  }
  if (!groupByOption.value) {
    groupByOption.value = groupByOptions[0];
  }
  if (!colourByOption.value) {
    colourByOption.value = colourByOptions[0];
  }
  await getData();
};
</script>
