import NuxtAxiosInstance from "@/plugins/axios";
import { Decimal } from "decimal.js";
import { DateObject } from "./datetime";

export type DateRange = [DateObject | null, DateObject | null];
export type FilterValue = string | number | boolean | DateObject | DateRange;
export type Filters = Record<string, FilterValue | FilterValue[]>;

export interface AggregateQuery {
  sum?: string[];
  average?: string[];
  min?: string[];
  max?: string[];
}

export type Aggregate = Record<string, Decimal | null>;

export class AggregateResponse {
  sum: Aggregate = {};
  average: Aggregate = {};
  min: Aggregate = {};
  max: Aggregate = {};
}

export class PaginatedResponse<T> {
  items: T[] = [];
  count: number = 0;
  aggregates: AggregateResponse = new AggregateResponse();
}

export interface Relationship {
  dataClass: typeof DatabaseObject;
  backref: string | null;
  jsonify?: boolean;
}

export class Metrics {}

export interface AuthCompany {
  id: number;
  name: string;
  primary_theme_color: string;
  secondary_theme_color: string;
}
export interface AuthUser {
  id: number;
  email: string;
  admin: boolean;
  company_id: number;
  company: AuthCompany;
  logo_url: string;
}

export class APIObject {
  static $axios: typeof NuxtAxiosInstance = NuxtAxiosInstance;
  static $route: string = "";

  static relationships: Record<string, Relationship> = {};

  metrics: Metrics = new Metrics();

  __relationshipLoaded: Record<string, boolean> = {};

  public static fromAxios<T extends APIObject | APIObject>(
    axiosData: T,
    parent: { object: any; key: keyof T } | null = null
  ): T {
    const object = new this() as T;
    for (const key in axiosData) {
      if (key in object) {
        let data: any = axiosData[key];
        if (key in this.relationships && data !== null) {
          object.__relationshipLoaded[key] = true;
          if (Array.isArray(data)) {
            data.sort((a, b) => (a.id || 0) - (b.id || 0));
            const items = data.map((x) =>
              this.relationships[key].dataClass.fromAxios(x, {
                object,
                key: this.relationships[key].backref as unknown as keyof T,
              })
            ) as APIObject[];
            data = items;
          } else {
            data = this.relationships[key].dataClass.fromAxios(data, {
              object,
              key: this.relationships[key].backref as unknown as keyof T,
            });
          }
        }
        object[key] = data;
      } else if (key in object.metrics) {
        // @ts-ignore
        object.metrics[key] = axiosData[key];
      }
    }
    if (parent) {
      if (parent.key in object) {
        object.__relationshipLoaded[parent.key as string] = true;
        if (Array.isArray(object[parent.key])) {
          const rels = object[parent.key] as unknown as DatabaseObject[];
          rels.push(parent.object);
        } else {
          object[parent.key] = parent.object;
        }
      }
    }
    return object;
  }

  public static fromAxiosList<T extends DatabaseObject>(axiosData: T[]): T[] {
    return axiosData.map((x) => this.fromAxios(x));
  }

  static async paginated<T extends DatabaseObject>(
    filters: Filters,
    sorts: [string, boolean][],
    page: number,
    perPage: number,
    aggregates: AggregateQuery = {},
    groupBy?: string[],
    overrideRoute?: string
  ): Promise<PaginatedResponse<T>> {
    const data = await this.$axios.$post<PaginatedResponse<T>>(
      `/${overrideRoute ? overrideRoute : this.$route}/paginated`,
      { filters, sorts, page, items_per_page: perPage, aggregates }
    );
    data.items = data.items.map((x) => this.fromAxios(x));
    return data;
  }
}

export class DatabaseObject extends APIObject {
  id: number | null = null;

  public static async get<T extends DatabaseObject>(
    id: number,
    overrideRoute?: string
  ) {
    return this.fromAxios(
      await this.$axios.$get<T>(
        `/${overrideRoute ? overrideRoute : this.$route}?id=${id}`
      )
    );
  }

  static async create<T extends DatabaseObject>(
    object: T,
    overrideRoute?: string
  ) {
    if (object.id !== null) {
      throw new Error("Cannot create an object that already has an id");
    }
    return this.fromAxios(
      await this.$axios.$post<T>(
        `/${overrideRoute ? overrideRoute : this.$route}`,
        object
      )
    );
  }

  static async update<T extends DatabaseObject>(
    object: T,
    overrideRoute?: string
  ) {
    if (object.id === null) {
      throw new Error("Cannot update an object that has no id");
    }
    return this.fromAxios(
      await this.$axios.$put<T>(
        `/${overrideRoute ? overrideRoute : this.$route}`,
        object
      )
    );
  }

  static async deleteObject<T extends DatabaseObject>(
    object: T,
    overrideRoute?: string
  ) {
    if (object.id) {
      await this.$axios.$delete<boolean>(
        `/${overrideRoute ? overrideRoute : this.$route}?id=${object.id}`
      );
    }
    // Remove backrefs
    Object.entries(this.relationships).forEach((element) => {
      // For each relationship
      const key = element[0] as keyof T;
      const backref = element[1].backref;
      // If the object has the relationship
      if (object[key]) {
        // If the relationship is an array
        if (Array.isArray(object[key])) {
          // For each item in the array
          const children = object[key] as unknown as DatabaseObject[];
          children.forEach((child) => {
            child.removeBackref(backref as keyof typeof child, object);
          });
        }
        // If the relationship is an object
        else {
          const child = object[key] as unknown as DatabaseObject;
          child.removeBackref(backref as keyof typeof child, object);
        }
      }
    });
  }

  removeBackref(backref: keyof this, object: DatabaseObject) {
    if (Array.isArray(this[backref])) {
      this[backref] = (this[backref] as unknown as DatabaseObject[]).filter(
        (x) => x !== object
      ) as unknown as this[typeof backref];
    } else {
      this[backref] = null as unknown as this[typeof backref];
    }
  }

  static async list<T extends DatabaseObject>(overrideRoute?: string) {
    return (
      await this.$axios.$get<T[]>(
        `/${overrideRoute ? overrideRoute : this.$route}/all`
      )
    ).map((x) => this.fromAxios(x));
  }

  static async filters<T extends DatabaseObject>(
    filter: Filters,
    overrideRoute?: string
  ): Promise<T[]> {
    return (
      await this.$axios.$post<T[]>(
        `/${overrideRoute ? overrideRoute : this.$route}/filters`,
        filter
      )
    ).map((x) => this.fromAxios(x));
  }

  async deleteObject<T extends typeof DatabaseObject>() {
    return await (<T>this.constructor).deleteObject(this);
  }

  async save<T extends typeof DatabaseObject>() {
    let object = null;
    if (this.id === null) {
      object = await (<T>this.constructor).create(this);
    } else {
      object = await (<T>this.constructor).update(this);
    }
    const relationshipKeys = Object.keys((<T>this.constructor).relationships);
    const relationships = (<T>this.constructor).relationships;
    for (const key in object) {
      if (
        !relationshipKeys.includes(key) ||
        object.__relationshipLoaded[key as string]
      ) {
        this[key] = object[key];
        if (relationshipKeys.includes(key) && relationships[key].backref) {
          const backref = relationships[key].backref as any;
          if (Array.isArray(this[key])) {
            const children = this[key] as unknown as DatabaseObject[];
            children.forEach((child) => {
              // @ts-ignore
              child[backref] = this;
            });
          } else {
            // @ts-ignore
            this[key][backref] = this;
          }
        }
      }
    }
  }

  forbiddenJSONKeys(): string[] {
    const forbiddenKeys = ["__relationshipLoaded"];
    const This = this.constructor as unknown as typeof DatabaseObject;
    for (const key in This.relationships) {
      if (!This.relationships[key].jsonify) {
        forbiddenKeys.push(key);
      }
    }
    return forbiddenKeys;
  }

  toJSON() {
    const json: Record<string, any> = {};
    const forbiddenKeys = this.forbiddenJSONKeys();
    for (const key in this) {
      if (!forbiddenKeys.includes(key)) {
        json[key] = this[key];
      }
    }
    return json;
  }
}

export type PaginatedFunction = typeof DatabaseObject.paginated;
