<template>
  <BaseDialog v-model="dialog" title="User">
    <v-text-field
      v-model="user.email"
      label="Email"
      variant="outlined"
      density="compact"
    />
    <v-text-field
      v-model="user.password"
      label="Password"
      type="password"
      variant="outlined"
      density="compact"
    />
    <v-text-field
      v-model="user.first_name"
      label="First Name"
      variant="outlined"
      density="compact"
    />
    <v-text-field
      v-model="user.last_name"
      label="Last Name"
      variant="outlined"
      density="compact"
    />
    <v-checkbox v-model="user.admin" label="Admin" density="compact" />
    <template #actions>
      <v-btn :loading="loading" @click="save">Save</v-btn>
    </template>
  </BaseDialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import BaseDialog from "../base/dialogs/BaseDialog.vue";
import { User } from "@/classes";

const emits = defineEmits(["change"]);

const dialog = ref(false);
const user = ref<User>(new User());
const loading = ref(false);

const open = async (id: number | null) => {
  dialog.value = true;
  if (id) {
    user.value = await User.get<User>(id);
  } else {
    user.value = new User();
  }
};

const save = async () => {
  loading.value = true;
  await user.value.save();
  loading.value = false;
  dialog.value = false;
  emits("change");
};

defineExpose({ open });
</script>
