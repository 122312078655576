<template>
  <div class="pa-3">
    <div class="d-flex">
      <h3>Sites</h3>
      <v-spacer />
      <v-btn color="primary" flat @click="createDepot">
        <v-icon class="mr-1">mdi-plus</v-icon>Site
      </v-btn>
    </div>
    <div
      v-for="depot in depots"
      :key="depot.id"
      class="d-flex align-center mt-2"
    >
      <v-row no-gutters>
        <v-col cols="3">
          <v-text-field
            v-model="depot.name"
            label="Name"
            variant="outlined"
            density="compact"
            hide-details
            @update:focused="!$event && depot.save()"
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            class="ml-2"
            v-model="depot.work_area_id"
            label="Region"
            :items="workAreas"
            item-value="id"
            item-title="name"
            variant="outlined"
            density="compact"
            hide-details
            @update:focused="!$event && depot.save()"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            class="ml-2"
            v-model="depot.latitude"
            label="Latitude"
            variant="outlined"
            density="compact"
            hide-details
            @update:focused="!$event && depot.save()"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            class="ml-2"
            v-model="depot.longitude"
            label="Longitude"
            variant="outlined"
            density="compact"
            hide-details
            @update:focused="!$event && depot.save()"
          />
        </v-col>
      </v-row>
      <v-btn
        class="ml-2"
        icon="mdi-delete"
        flat
        density="compact"
        @click="deleteDepot(depot)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { WorkArea, Depot } from "@/classes";
import { useConfirmStore } from "@/store/confirm";
import { useListStore } from "@/store/lists";
import { onMounted, onUnmounted, ref } from "vue";

const $confirm = useConfirmStore();
const lists = useListStore();
const workAreas = lists.useList<WorkArea>("workAreas");

const depots = ref<Depot[]>([]);
onMounted(async () => {
  depots.value = await Depot.list<Depot>();
  depots.value.sort((a, b) => a.id - b.id);
});
onUnmounted(() => {
  lists.refreshList("depots");
});

const createDepot = async () => {
  const newDepot = new Depot();
  await newDepot.save();
  depots.value.push(newDepot);
};

const deleteDepot = async (depot: Depot) => {
  $confirm.open({
    onConfirm: async () => {
      depots.value = depots.value.filter((d) => d.id !== depot.id);
      await depot.deleteObject();
    },
  });
};
</script>
