<template>
  <v-dialog v-model="model" :max-width="maxWidth" scrollable>
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="primary" flat density="compact">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <slot name="toolbar-actions" />
          <v-btn icon="mdi-close" @click="model = false" />
        </v-toolbar>
      </v-card-title>
      <v-card-text :class="contentClass">
        <slot />
      </v-card-text>
      <v-card-actions v-if="!hideActions" :class="actionsClass">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { defineModel } from "vue";

const model = defineModel<boolean>();

interface Props {
  title: string;
  maxWidth?: string;
  contentClass?: string;
  actionsClass?: string;
  hideActions?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  maxWidth: "500",
  contentClass: "pa-4",
  actionsClass: "py-0 px-4",
  hideActions: false,
});
</script>
