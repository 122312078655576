<template>
  <div class="pa-3">
    <div class="d-flex">
      <h3>Roles</h3>
      <v-spacer />
      <v-btn color="primary" flat @click="createRole">
        <v-icon class="mr-1">mdi-plus</v-icon>Role
      </v-btn>
    </div>
    <div v-for="role in roles" :key="role.id" class="d-flex align-center mt-2">
      <v-text-field
        v-model="role.name"
        label="Name"
        variant="outlined"
        density="compact"
        hide-details
        @update:focused="!$event && role.save()"
      />
      <v-btn
        class="ml-2"
        icon="mdi-delete"
        flat
        density="compact"
        @click="deleteRole(role)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Role } from "@/classes";
import { useConfirmStore } from "@/store/confirm";
import { useListStore } from "@/store/lists";
import { onMounted, onUnmounted, ref } from "vue";

const $confirm = useConfirmStore();
const lists = useListStore();
onUnmounted(() => {
  lists.refreshList("roles");
});

const roles = ref<Role[]>([]);
onMounted(async () => {
  roles.value = await Role.list<Role>();
  roles.value.sort((a, b) => a.id - b.id);
});

const createRole = async () => {
  const newRole = new Role();
  await newRole.save();
  roles.value.push(newRole);
};

const deleteRole = async (role: Role) => {
  $confirm.open({
    onConfirm: async () => {
      roles.value = roles.value.filter((r) => r.id !== role.id);
      await role.deleteObject();
    },
  });
};
</script>
