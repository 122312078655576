<template>
  <div>
    <component :is="route.meta.layout">
      <router-view />
    </component>

    <v-snackbar
      v-model="notifierState.opened"
      :color="notifierState.color"
      :timeout="notifierState.timeout"
    >
      {{ notifierState.message }}
    </v-snackbar>

    <v-dialog v-model="$confirm.opened.value" max-width="400">
      <v-card>
        <v-card-title>
          <div class="d-flex align-center">
            <b>{{ $confirm.state.title }}</b>
            <v-spacer />
            <v-btn
              icon="mdi-close"
              flat
              density="compact"
              @click="$confirm.cancel"
            />
          </div>
        </v-card-title>
        <v-card-text v-if="!!$confirm.state.content" class="px-4 py-2">
          {{ $confirm.state.content }}
        </v-card-text>
        <v-card-actions>
          <v-btn variant="text" @click="$confirm.confirm">
            {{ $confirm.state.confirmText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from "vue-router";
import { useNotifierStore } from "./store/notifier";
import { useConfirmStore } from "./store/confirm";

const $notifier = useNotifierStore();
const notifierState = $notifier.state;

const $confirm = useConfirmStore();

const route = useRoute();
</script>

<style>
@import "./assets/global.css";
</style>
