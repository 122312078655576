import { defineStore } from "pinia";
import { reactive } from "vue";

export interface ConfirmStateSafe {
  title: string;
  color: string;
  content: string;
  confirmText: string;
  cancelText: string;
  confirmArgs: any[];
  cancelArgs: any[];
  onConfirm: (...args: any[]) => void;
  onCancel: (...args: any[]) => void;
}

export interface ConfirmState {
  title?: string;
  color?: string;
  content?: string;
  confirmText?: string;
  cancelText?: string;
  confirmArgs?: any[];
  cancelArgs?: any[];
  onConfirm?: (...args: any[]) => void;
  onCancel?: (...args: any[]) => void;
}

const defaultState = {
  title: "Are you sure?",
  color: "",
  content: "",
  confirmText: "Confirm",
  cancelText: "Cancel",
  confirmArgs: [],
  cancelArgs: [],
  onConfirm: () => {},
  onCancel: () => {},
} as ConfirmStateSafe;

export const useConfirmStore = defineStore("confirm", () => {
  const opened = reactive({ value: false });
  const state = reactive({ ...defaultState } as ConfirmStateSafe);
  const confirm = () => {
    state.onConfirm(...state.confirmArgs);
    opened.value = false;
  };
  const cancel = () => {
    state.onCancel(...state.cancelArgs);
    opened.value = false;
  };
  const open = (confirm: ConfirmState = defaultState) => {
    Object.assign(state, { ...defaultState }, confirm);
    opened.value = true;
  };

  return { opened, state, confirm, cancel, open };
});
