import { DatabaseObject } from "./class";
import type { AssessmentTemplate, Question, Rating } from "./assessment";
import { AssetGroup, Client, Depot, Location, WorkArea } from "./config";
import { User } from "./user";

export class InterviewRole extends DatabaseObject {
  static $route = "interview-role";

  interview_id: number | null = null;
  role_id: number | null = null;
}

export class Interview extends DatabaseObject {
  static $route = "interview";

  name: string = "";
  assessment_completion_id: number | null = null;

  interview_roles: InterviewRole[] = [];

  get role_ids() {
    return this.interview_roles.map((role) => role.role_id);
  }

  async manageRoles(roleIds: number[]) {
    for (const roleId of roleIds) {
      const existingRole = this.interview_roles.find(
        (role) => role.role_id === roleId
      );
      if (!existingRole) {
        const newRole = new InterviewRole();
        newRole.interview_id = this.id;
        newRole.role_id = roleId;
        this.interview_roles.push(newRole);
        await newRole.save();
      }
    }
    const deleteRoleIds = this.role_ids.filter(
      (roleId) => !roleIds.includes(roleId)
    );
    for (const roleId of deleteRoleIds) {
      const existingRole = this.interview_roles.find(
        (role) => role.role_id === roleId
      );
      if (existingRole) {
        this.interview_roles = this.interview_roles.filter(
          (role) => role.id !== existingRole.id
        );
        await existingRole.deleteObject();
      }
    }
  }
}

export class AssessmentCompletion extends DatabaseObject {
  static $route = "assessment-completion";

  name: string = "";
  assessment_template_id: number | null = null;
  date: Date = new Date();
  client_id: number | null = null;
  location_id: number | null = null;
  work_area_id: number | null = null;
  depot_id: number | null = null;
  asset_group_id: number | null = null;
  completed_by_id: number | null = null;

  assessment_template: AssessmentTemplate | null = null;
  client: Client | null = null;
  location: Location | null = null;
  work_area: WorkArea | null = null;
  depot: Depot | null = null;
  asset_group: AssetGroup | null = null;
  completed_by: User | null = null;
  interviews: Interview[] = [];

  average_score: number = 0;
  completion_rate: number = 0;

  get assessment_template_name() {
    return this.assessment_template?.name || "";
  }

  get client_name() {
    return this.client?.name || "";
  }

  get location_name() {
    return this.location?.name || "";
  }

  get work_area_name() {
    return this.work_area?.name || "";
  }

  get depot_name() {
    return this.depot?.name || "";
  }

  get asset_group_name() {
    return this.asset_group?.name || "";
  }

  get completed_by_name() {
    return this.completed_by?.name || "";
  }
}

export class Answer extends DatabaseObject {
  static $route = "answer";

  interview_id: number | null = null;
  assessment_completion_id: number | null = null;
  question_id: number | null = null;
  rating_id: number | null = null;
  role_id: number | null = null;
  comment: string = "";

  assessment_completion: AssessmentCompletion | null = null;
  question: Question | null = null;
  rating: Rating | null = null;
}
