import { FilterDefinition, PageFilters } from "@/types/filter";
import { defineStore } from "pinia";
import { reactive, computed } from "vue";

export interface FilterState {
  filters: PageFilters;
  drawer: boolean;
  name: string;
}

export const useFilterStore = defineStore("filter", () => {
  const state = reactive<FilterState>({
    filters: {},
    drawer: false,
    name: "",
  });

  const definitions = computed(() => {
    if (state.name) {
      return state.filters[state.name].definitions;
    }
    return [];
  });

  const values = computed(() => {
    if (state.name) {
      return state.filters[state.name].values;
    }
    return {};
  });

  const initialise = (name: string, filterDefinitions: FilterDefinition[]) => {
    state.name = name;
    if (!state.filters[name]) {
      state.filters[name] = {
        definitions: filterDefinitions,
        values: {},
      };
    }
  };

  const hide = () => {
    state.name = "";
  };

  const clearAll = () => {
    if (state.filters[state.name]) {
      state.filters[state.name].values = {};
    }
  };

  return {
    state,
    definitions,
    values,
    initialise,
    hide,
    clearAll,
  };
});
