<template>
  <div class="pa-3 d-flex height-full">
    <v-table class="flex-grow-1 mr-8">
      <thead>
        <tr>
          <th>Area</th>
          <th>Average Score</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="clientMetric in clientMetrics"
          :key="`client-${clientMetric.id}`"
        >
          <tr>
            <td>
              <v-btn
                class="mr-1"
                :icon="`mdi-menu-${
                  expandedClientIds.includes(clientMetric.id) ? 'down' : 'right'
                }`"
                flat
                density="compact"
                @click="expand('Client', clientMetric.id)"
              />{{ clientMetric.name }}
            </td>
            <td>{{ clientMetric.average.toFixed(2) }}</td>
          </tr>
          <template
            v-for="locationMetric in expandedClientIds.includes(clientMetric.id)
              ? clientMetric.location_metrics
              : []"
            :key="`location-${locationMetric.id}`"
          >
            <tr>
              <td class="pl-7">
                <v-btn
                  class="mr-1"
                  :icon="`mdi-menu-${
                    expandedLocationIds.includes(locationMetric.id)
                      ? 'down'
                      : 'right'
                  }`"
                  flat
                  density="compact"
                  @click="expand('Location', locationMetric.id)"
                />{{ locationMetric.name }}
              </td>
              <td>{{ locationMetric.average.toFixed(2) }}</td>
            </tr>
            <template
              v-for="workAreaMetric in expandedLocationIds.includes(
                locationMetric.id
              )
                ? locationMetric.work_area_metrics
                : []"
              :key="`work-area-${workAreaMetric.id}`"
            >
              <tr>
                <td class="pl-10">
                  <v-btn
                    class="mr-1"
                    :icon="`mdi-menu-${
                      expandedWorkAreaIds.includes(workAreaMetric.id)
                        ? 'down'
                        : 'right'
                    }`"
                    flat
                    density="compact"
                    @click="expand('Work Area', workAreaMetric.id)"
                  />{{ workAreaMetric.name }}
                </td>
                <td>{{ workAreaMetric.average.toFixed(2) }}</td>
              </tr>
              <template
                v-for="depotMetric in expandedWorkAreaIds.includes(
                  workAreaMetric.id
                )
                  ? workAreaMetric.depot_metrics
                  : []"
                :key="`depot-${depotMetric.id}`"
              >
                <tr>
                  <td class="pl-13">
                    <v-btn
                      class="mr-1"
                      :icon="`mdi-menu-${
                        expandedDepotIds.includes(depotMetric.id)
                          ? 'down'
                          : 'right'
                      }`"
                      flat
                      density="compact"
                      @click="expand('Depot', depotMetric.id)"
                    />{{ depotMetric.name }}
                  </td>
                  <td>{{ depotMetric.average.toFixed(2) }}</td>
                </tr>
                <tr
                  v-for="assetGroupMetric in expandedDepotIds.includes(
                    depotMetric.id
                  )
                    ? depotMetric.asset_group_metrics
                    : []"
                  :key="`asset-group-${assetGroupMetric.id}`"
                >
                  <td style="padding-left: 86px">
                    {{ assetGroupMetric.name }}
                  </td>
                  <td>
                    {{ assetGroupMetric.average.toFixed(2) }}
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </template>
      </tbody>
    </v-table>
    <div id="mapContainer" class="map-container" />
  </div>
</template>

<script setup lang="ts">
import { GeoJSON } from "geojson";
import axiosInstance from "@/plugins/axios";
import { computed, onMounted, ref, watch } from "vue";
import mapboxgl, { Map, GeoJSONSource } from "mapbox-gl";
import { useFilterStore } from "@/store/filter";
import { FilterDefinition } from "@/types/filter";
import {
  AssetGroupMetric,
  ClientMetric,
  DepotMetric,
  LocationMetric,
  MapMetrics,
  WorkAreaMetric,
} from "@/types/metrics";
import "mapbox-gl/dist/mapbox-gl.css";

const assetGroupMetrics = computed(() => {
  const assetGroupMetrics: Record<number, AssetGroupMetric> = {};
  metrics.value.forEach((metric) => {
    if (!assetGroupMetrics[metric.asset_group_id]) {
      assetGroupMetrics[metric.asset_group_id] = new AssetGroupMetric(
        metric.asset_group_id,
        metric.asset_group_name,
        metric.latitude,
        metric.longitude,
        [metric.average],
        metric.depot_id,
        metric.depot_name,
        metric.work_area_id,
        metric.work_area_name,
        metric.location_id,
        metric.location_name,
        metric.client_id,
        metric.client_name
      );
    } else {
      assetGroupMetrics[metric.asset_group_id].averages.push(metric.average);
    }
  });
  return Object.values(assetGroupMetrics);
});

const depotMetrics = computed(() => {
  const depotMetrics: Record<number, DepotMetric> = {};
  assetGroupMetrics.value.forEach((metric) => {
    if (!depotMetrics[metric.depot_id]) {
      depotMetrics[metric.depot_id] = new DepotMetric(
        metric.depot_id,
        metric.depot_name,
        metric.latitude,
        metric.longitude,
        [metric],
        metric.work_area_id,
        metric.work_area_name,
        metric.location_id,
        metric.location_name,
        metric.client_id,
        metric.client_name
      );
    } else {
      depotMetrics[metric.depot_id].asset_group_metrics.push(metric);
    }
  });
  return Object.values(depotMetrics);
});

const workAreaMetrics = computed(() => {
  const workAreaMetrics: Record<number, WorkAreaMetric> = {};
  depotMetrics.value.forEach((metric) => {
    if (!workAreaMetrics[metric.work_area_id]) {
      workAreaMetrics[metric.work_area_id] = new WorkAreaMetric(
        metric.work_area_id,
        metric.work_area_name,
        [metric],
        metric.location_id,
        metric.location_name,
        metric.client_id,
        metric.client_name
      );
    } else {
      workAreaMetrics[metric.work_area_id].depot_metrics.push(metric);
    }
  });
  return Object.values(workAreaMetrics);
});

const locationMetrics = computed(() => {
  const locationMetrics: Record<number, LocationMetric> = {};
  workAreaMetrics.value.forEach((metric) => {
    if (!locationMetrics[metric.location_id]) {
      locationMetrics[metric.location_id] = new LocationMetric(
        metric.location_id,
        metric.location_name,
        [metric],
        metric.client_id,
        metric.client_name
      );
    } else {
      locationMetrics[metric.location_id].work_area_metrics.push(metric);
    }
  });
  return Object.values(locationMetrics);
});

const clientMetrics = computed(() => {
  const clientMetrics: Record<number, ClientMetric> = {};
  locationMetrics.value.forEach((metric) => {
    if (!clientMetrics[metric.client_id]) {
      clientMetrics[metric.client_id] = new ClientMetric(
        metric.client_id,
        metric.client_name,
        [metric]
      );
    } else {
      clientMetrics[metric.client_id].location_metrics.push(metric);
    }
  });
  return Object.values(clientMetrics);
});

const mapMetrics = computed(() => {
  return depotMetrics.value.filter((m) => m.latitude && m.longitude);
});

let map: Map | null = null;
const metrics = ref<MapMetrics[]>([]);

const expandedClientIds = ref<number[]>([]);
const expandedLocationIds = ref<number[]>([]);
const expandedWorkAreaIds = ref<number[]>([]);
const expandedDepotIds = ref<number[]>([]);

const expand = (
  type: "Client" | "Location" | "Work Area" | "Depot",
  itemId: number
) => {
  let refIds = expandedClientIds;
  if (type === "Location") {
    refIds = expandedLocationIds;
  } else if (type === "Work Area") {
    refIds = expandedWorkAreaIds;
  } else if (type === "Depot") {
    refIds = expandedDepotIds;
  }
  if (refIds.value.includes(itemId)) {
    refIds.value = refIds.value.filter((id) => id !== itemId);
  } else {
    refIds.value.push(itemId);
  }
};

const filterStore = useFilterStore();
const filterDefinitions: FilterDefinition[] = [
  {
    type: "reflist",
    reflist: "processes",
    value: "process_ids",
    label: "Process",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "processSteps",
    value: "process_step_ids",
    label: "Process Step",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "clients",
    value: "client_ids",
    label: "Company",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "locations",
    value: "location_ids",
    label: "Business Unit",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "workAreas",
    value: "work_area_ids",
    label: "Region",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "depots",
    value: "depot_ids",
    label: "Site",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "assetGroups",
    value: "asset_group_ids",
    label: "Asset Group",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "roles",
    value: "role_ids",
    label: "Role Being Assessed",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
  {
    type: "reflist",
    reflist: "users",
    value: "user_ids",
    label: "Completed By",
    itemValue: "id",
    itemText: "name",
    multiple: true,
  },
];

filterStore.initialise("map", filterDefinitions);

watch(
  () => filterStore.values,
  async () => {
    await getData();
  },
  { deep: true }
);

const getData = async () => {
  metrics.value = await axiosInstance.$post<MapMetrics[]>(
    "/metrics/map",
    filterStore.values
  );

  const averageFeatures: GeoJSON.Feature<GeoJSON.Point>[] =
    mapMetrics.value.map((metric) => {
      return {
        type: "Feature",
        properties: {
          average: metric.average,
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(metric.longitude),
            parseFloat(metric.latitude),
          ],
        },
      };
    });

  const averageData: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: averageFeatures,
  };

  if (map.getSource("averages")) {
    (map.getSource("averages") as GeoJSONSource).setData(averageData);
  } else {
    map.addSource("averages", {
      type: "geojson",
      data: averageData,
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
      clusterProperties: {
        sum: ["+", ["get", "average"]],
      },
    });

    map.addLayer({
      id: "individual-average",
      type: "circle",
      source: "averages",
      paint: {
        "circle-color": [
          "interpolate",
          ["linear"],
          ["get", "average"],
          1,
          "#FF5A5A",
          3,
          "#FFA500",
          5,
          "#32CD32",
        ],
        "circle-radius": ["*", ["get", "average"], 8],
      },
    });

    map.addLayer({
      id: "individual-average-text",
      type: "symbol",
      source: "averages",
      layout: {
        "text-field": [
          "number-format",
          ["get", "average"],
          { "min-fraction-digits": 2, "max-fraction-digits": 2 },
        ],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
      },
    });

    map.addLayer({
      id: "clustered-average",
      type: "circle",
      source: "averages",
      filter: ["has", "sum"],
      paint: {
        "circle-color": [
          "interpolate",
          ["linear"],
          ["/", ["get", "sum"], ["get", "point_count"]],
          1,
          "#FF5A5A",
          3,
          "#FFA500",
          5,
          "#32CD32",
        ],
        "circle-radius": [
          "*",
          ["/", ["get", "sum"], ["get", "point_count"]],
          8,
        ],
      },
    });

    map.addLayer({
      id: "clustered-average-text",
      type: "symbol",
      source: "averages",
      filter: ["has", "sum"],
      layout: {
        "text-field": [
          "number-format",
          ["/", ["get", "sum"], ["get", "point_count"]],
          { "min-fraction-digits": 2, "max-fraction-digits": 2 },
        ],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
      },
    });
  }
};

onMounted(async () => {
  mapboxgl.accessToken =
    "pk.eyJ1IjoiZmx5dGV4IiwiYSI6ImNsbXZteTgyZTBrM3Aya2xkbDFoaHdma3AifQ.z9D51ngjyXuX2M1LKzKYvg";

  map = new Map({
    container: "mapContainer",
    style: "mapbox://styles/mapbox/standard",
    center: [134.489563, -25.734968],
    zoom: 3,
  });

  map.on("load", async () => {
    await getData();
  });
});
</script>

<style scoped>
.map-container {
  width: 50%;
  height: 100%;
}
</style>
