<template>
  <BaseDialog v-model="dialog" title="Template Rating">
    <div v-for="(rating, i) in ratings" :key="rating.id" class="d-flex">
      <span class="mt-2 mr-2">{{ rating.number }}.</span>
      <v-text-field
        v-model="rating.name"
        label="Name"
        variant="outlined"
        density="compact"
        @update:focused="!$event && rating.save()"
      />
      <v-btn
        v-if="i === ratings.length - 1"
        class="mt-2 ml-2"
        icon="mdi-delete"
        flat
        density="compact"
        @click="deleteRating(rating)"
      />
    </div>
    <template #actions>
      <v-btn @click="add">Add</v-btn>
    </template>
  </BaseDialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import BaseDialog from "../base/dialogs/BaseDialog.vue";
import { Rating } from "@/classes";
import { useConfirmStore } from "@/store/confirm";

const emits = defineEmits(["close"]);

const $confirm = useConfirmStore();

const dialog = ref(false);
const ratings = ref<Rating[]>([]);
const templateId = ref<number | null>(null);

watch(
  () => dialog.value,
  () => {
    if (!dialog.value) {
      emits("close");
    }
  }
);

const open = async (assessmentTemplateId: number) => {
  dialog.value = true;
  templateId.value = assessmentTemplateId;
  ratings.value = [];
  ratings.value = await Rating.filters<Rating>({
    assessment_template_id: assessmentTemplateId,
  });
  ratings.value.sort((a, b) => a.number - b.number);
};

const add = async () => {
  const newRating = new Rating();
  newRating.assessment_template_id = templateId.value;
  newRating.number = ratings.value.length + 1;
  await newRating.save();
  ratings.value.push(newRating);
};

const deleteRating = async (rating: Rating) => {
  $confirm.open({
    onConfirm: async () => {
      ratings.value = ratings.value.filter((r) => r.id !== rating.id);
      await rating.deleteObject();
    },
  });
};

defineExpose({ open });
</script>
